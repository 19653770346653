<template>
  <div class="columns mobile">
    <div class="column is-4 text-left">
      <b-icon
        icon="bars"
        class="has-text-white-always mt-4 cursor-pointer"
        size="is-medium"
        @click.native="toggleSidebarMobile"
      />
    </div>
    <div class="column is-4 text-center">
      <b-image
        src="/images/logo_white_symbol.png"
        alt="Capvital logo"
        class="w-16 m-auto"
      />
    </div>
    <div class="column is-4 text-right">
      <b-icon
        icon="search"
        class="has-text-white-always mt-4 cursor-pointer"
        size="is-medium"
        @click.native="search"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "topBarMobile",
  methods: {
    toggleSidebarMobile() {
      this.$store.dispatch("setSidebarOpen", true);
      this.$store.dispatch("setSidebarOpenMobile", true);
    },
    search() {
      this.$emit("search", "'");
    }
  }
};
</script>
