// DEPENDENCIES IMPORTS
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueCurrencyFilter from "vue-currency-filter";
import Dashboardv1 from "./layouts/Dashboardv1.vue";
import CapvitalFiles from "./components/Files/CapvitalFiles";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import VueCurrencyInput from "vue-currency-input";
import "./vendors/vee-validate";
import PortalVue from "portal-vue";
import { notification, idToShow } from "@/vendors/helpers";
import Preview from "@/components/generals/Preview";
import {
  faTachometerAlt,
  faChartBar,
  faDollarSign,
  faLandmark,
  faBook,
  faBullhorn,
  faWallet,
  faUsers,
  faEye,
  faCog,
  faSignOutAlt,
  faShieldAlt,
  faQuestion,
  faUserSecret,
  faCaretDown,
  faUser,
  faBuilding,
  faKey,
  faCalendarAlt,
  faPercentage,
  faTimes,
  faEquals,
  faBars,
  faTrash,
  faEllipsisV,
  faPen,
  faSave,
  faChevronRight,
  faTimesCircle,
  faBookOpen,
  faHeadset,
  faCheckCircle,
  faChevronLeft,
  faPencilRuler,
  faPauseCircle,
  faThumbsUp,
  faThumbsDown,
  faExclamationCircle,
  faBullseye,
  faCreditCard,
  faFileContract,
  faMoon,
  faSun,
  faCircle,
  faHandshake,
  faExclamationTriangle,
  faArchive,
  faFileWord,
  faFileCsv,
  faFileAlt,
  faFilePdf,
  faFilePowerpoint,
  faFileExcel,
  faFolder,
  faFileImage,
  faArrowRight,
  faPaperPlane,
  faReply,
  faCaretUp,
  faDove,
  faGripVertical,
  faColumns,
  faSync,
  faChevronDown,
  faHandHoldingUsd,
  faUniversity,
  faMoneyCheckAlt,
  faHdd,
  faTh,
  faChevronUp,
  faArrowLeft,
  faStar,
  faCopy,
  faPaste,
  faInfo,
  faKeyboard,
  faCommentDots,
  faCheckDouble,
  faFileInvoice,
  faSpinner,
  faFunnelDollar,
  faTasks,
  faExclamation,
  faInfoCircle,
  faExpand,
  faCompress,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-free/js/all";

// STYLE IMPORTS
import "typeface-ubuntu";
import "nprogress/nprogress.css";
import "animate.css";
import "@/scss/tailwind.css";
import "@/scss/_themes.scss";
import "@/scss/_transitions.scss";

// FONTAWESOME ICONS
library.add(
  faTachometerAlt,
  faChartBar,
  faDollarSign,
  faLandmark,
  faBook,
  faBullhorn,
  faWallet,
  faUsers,
  faEye,
  faCog,
  faSignOutAlt,
  faShieldAlt,
  faQuestion,
  faUserSecret,
  faCaretDown,
  faUser,
  faBuilding,
  faKey,
  faCalendarAlt,
  faPercentage,
  faTimes,
  faEquals,
  faBars,
  faTrash,
  faEllipsisV,
  faPen,
  faSave,
  faChevronRight,
  faTimesCircle,
  faBookOpen,
  faHeadset,
  faCheckCircle,
  faChevronLeft,
  faPencilRuler,
  faPauseCircle,
  faThumbsUp,
  faThumbsDown,
  faExclamationCircle,
  faBullseye,
  faCreditCard,
  faFileContract,
  faMoon,
  faSun,
  faCircle,
  faQuestion,
  faHandshake,
  faExclamationTriangle,
  faArchive,
  faFileWord,
  faFileCsv,
  faFileAlt,
  faFilePdf,
  faFilePowerpoint,
  faFileExcel,
  faFolder,
  faFileImage,
  faArrowRight,
  faEllipsisV,
  faPaperPlane,
  faReply,
  faCaretDown,
  faCaretUp,
  faDove,
  faGripVertical,
  faColumns,
  faSync,
  faChevronDown,
  faHandHoldingUsd,
  faUniversity,
  faMoneyCheckAlt,
  faHdd,
  faTh,
  faChevronUp,
  faBars,
  faArrowLeft,
  faStar,
  faCopy,
  faPaste,
  faInfo,
  faKeyboard,
  faCommentDots,
  faCheckDouble,
  faFileInvoice,
  faSpinner,
  faFunnelDollar,
  faTasks,
  faExclamation,
  faInfoCircle,
  faExpand,
  faCompress,
  faBan,
);

moment.locale("es");
// VUE USES
Vue.use(VueMoment, {
  moment
});
Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultIconComponent: "fa-icon",
  defaultDateFormatter: date => moment(date).format("DD-MM-YYYY")
});
Vue.use(PortalVue);
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 2,
  fractionSeparator: ".",
  symbolPosition: "front",
  symbolSpacing: false
});
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: "USD",
    locale: "en",
    allowNegative: "false"
  }
});
// VUE COMPONENTS
Vue.component("fa-icon", FontAwesomeIcon);
Vue.component("Layout", Dashboardv1);
Vue.component("capvital-files", CapvitalFiles);
Vue.component("preview", Preview);
Vue.mixin({
  methods: {
    notification: notification,
    idToShow: idToShow
  }
});

Vue.prototype.$screen = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
});

window.addEventListener("resize", () => {
  Vue.prototype.$screen.width = window.innerWidth;
  Vue.prototype.$screen.height = window.innerHeight;
});

// VUE FILTERS
Vue.filter("hp", function(value) {
  if (store.state.currency == "currency") {
    return value;
  } else {
    value = value.replace("$", "");
    value = value.replace(",", "");
    let galeones = Math.floor(value / 126.71);
    value = Math.floor(value % 126.71);
    let text = galeones + " galeones, ";
    if (value > 6.57) {
      let sickles = Math.floor(value / 6.57);
      value = Math.floor(value % 6.57);
      text += sickles + " sickles, ";
    }
    if (value > 0.23) {
      let knuts = Math.floor(value / 0.23);
      text += knuts + " knuts";
    }
    return text;
  }
});
// VUE INSTANCE
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
