import PagosApi from "@/api/pagos.api";
import { base64ToBlob } from "@/vendors/helpers"

const initialState = {
  pago: null,
  comentarios: null,
  documentos: null,
  ticket: null,
  dashboard: null,
  disposicion: null,
  simulacion: null,
  cuentaCP: null,
  garantia: null
};

const state = initialState;

const getters = {
  getPago: state => state.pago,
  getComentarios: state => state.comentarios,
  getDocumentos: state => state.documentos,
  getDashboard: state => state.dashboard,
  getDisposicion: state => state.disposicion,
  getSimulacion: state => state.simulacion,
  getCuentaCP: state => state.cuentaCP,
  getGarantia: state => state.garantia
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },
  SET_PAGO(state, pago) {
    state.pago = pago;
  },
  SET_COMENTARIOS(state, comentarios) {
    state.comentarios = comentarios;
  },
  SET_DOCUMENTOS(state, docs) {
    state.documentos = docs;
  },
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  SET_DASHBOARD(state, dashboard) {
    state.dashboard = dashboard;
  },
  SET_DISPOSICION(state, disposicion) {
    state.disposicion = disposicion;
  },
  SET_SIMULACION(state, simulacion) {
    state.simulacion = simulacion;
  },
  SET_CUENTA_CP(state, cuenta) {
    state.cuentaCP = cuenta;
  },
  SET_GARANTIA(state, garantia) {
    state.garantia = garantia;
  }
};
const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  getPago({ commit }, pago_id) {
    return PagosApi.getPago(
      pago_id,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getComentarios({ commit }, pago_id) {
    return PagosApi.getComentarios(
      pago_id,
      res => {
        commit("SET_COMENTARIOS", res.data.comments);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  agregarComentario({ commit }, data) {
    return PagosApi.setComentario(
      data,
      res => {
        commit("SET_COMENTARIOS", res.data.comments);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getDocumentos({ commit }, id) {
    return PagosApi.getDocumentos(
      id,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  ligarArchivos({ commit }, form) {
    return PagosApi.ligarArchivos(
      form,
      res => {
        commit("SET_DOCUMENTOS", res.data.archivos);
        return res.data;
      },
      () => false
    );
  },
  desligarArchivos({ commit }, data) {
    return PagosApi.desligarArchivos(
      data,
      res => {
        commit("SET_DOCUMENTOS", res.data);
        return res.data;
      },
      () => false
    );
  },
  getTicket({ commit }, pago_id) {
    try {
      return PagosApi.getTicket(
        pago_id,
        res => {
          let ticketWindow = null;
          if (res.data.pdf) {
            commit("SET_TICKET", res.data.pdf);
            //Construye una nueva ventana para mostrar el pdf como popup
            ticketWindow = window.open(
              "",
              "TicketWindow",
              "width=500,height=700"
            );
            // let obj = ticketWindow.document.createElement("object");
            // obj.style.width = "100%";
            // obj.style.height = "842pt";
            // obj.type = "application/pdf";
            // obj.data = "data:application/pdf;base64," + res.data.pdf;
            ticketWindow.document.title = "Ticket del pago #" + pago_id;
            const blob = base64ToBlob(res.data.pdf, 'application/pdf');
            const url = URL.createObjectURL(blob);
            ticketWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
            // ticketWindow.document.body.appendChild(obj);
          }
          return res.data;
        },
        () => false
      );
    } catch (error) {
      console.error(error);
    }
  },
  getDashboard({ commit }, data) {
    return PagosApi.getDashboard(
      data,
      res => {
        commit("SET_DASHBOARD", res.data);
        return res.data;
      },
      () => false
    );
  },
  getDisposicion({ commit }, disp_id) {
    return PagosApi.getDisposicion(
      disp_id,
      res => {
        commit("SET_DISPOSICION", res.data);
        return res.data;
      },
      () => false
    );
  },
  simular({ commit }, data) {
    return PagosApi.simular(
      data,
      res => {
        commit("SET_SIMULACION", res.data);
        return res.data;
      },
      () => false
    );
  },
  pagarDisposicion({ commit }, data) {
    return PagosApi.pagarDisposicion(
      data,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      () => false
    );
  },
  pagarDisposicionEspecial({ commit }, data) {
    return PagosApi.pagarDisposicionEspecial(
      data,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      () => false
    );
  },
  getCuentaCP({ commit }, id) {
    return PagosApi.getCuentaCP(
      id,
      res => {
        commit("SET_CUENTA_CP", res.data);
        return res.data;
      },
      () => false
    );
  },
  pagarCuentaCP({ commit }, data) {
    return PagosApi.pagarCuentaCP(
      data,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      () => false
    );
  },
  getGarantiaPasivo({ commit }, id) {
    return PagosApi.getGarantiaPasivo(
      id,
      res => {
        commit("SET_GARANTIA", res.data);
        return res.data;
      },
      () => false
    );
  },
  getGarantiaActivo({ commit }, id) {
    return PagosApi.getGarantiaActivo(
      id,
      res => {
        commit("SET_GARANTIA", res.data);
        return res.data;
      },
      () => false
    );
  },
  pagarGarantiaPasivo({ commit }, data) {
    return PagosApi.pagarGarantiaPasivo(
      data,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      () => false
    );
  },
  cobrarGarantiaActivo({ commit }, data) {
    return PagosApi.cobrarGarantiaActivo(
      data,
      res => {
        commit("SET_PAGO", res.data);
        return res.data;
      },
      () => false
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
