<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          ¿Tienes alguna duda?
        </p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </header>
      <section class="modal-card-body has-background-white">
        <p>Recuerda tus datos:</p>
        <div class="columns mt-0">
          <div class="column">
            <div class="control inline-block">
              <b-taglist attached>
                <b-tag type="is-light">Folio de cliente</b-tag>
                <b-tag type="is-info">{{ getMe.cliente.id }}</b-tag>
              </b-taglist>
            </div>
            <div class="control inline-block ml-4">
              <b-taglist attached>
                <b-tag type="is-light">Folio de usuario</b-tag>
                <b-tag type="is-info">{{ getMe.id }}</b-tag>
              </b-taglist>
            </div>
          </div>
        </div>
        <a href="https://ayuda.capvital.mx/" target="_blank">
          <b-message type="is-info" class="msg-hover" has-icon icon="book-open">
            <span class="font-bold">
              ¿Dudas de algún modulo o funcionamiento de capvital?
            </span>
            <p class="has-text-grey-darker-always">
              Da click aquí para ver la guia de usuario
            </p>
          </b-message>
        </a>
        <div class="mt-5">
          <a href="https://soporte.uvicate.com/" target="_blank">
            <b-message
              type="is-warning"
              class="msg-hover"
              has-icon
              icon="headset"
            >
              <span class="font-bold">¿Sigues con dudas?</span>
              <p class="has-text-grey-darker-always">
                Da click aquí para contactarte con el equipo de soporte
              </p>
            </b-message>
          </a>
        </div>
        <div class="mt-4" v-if="getAyudaActual">
          <p>
            <b-button type="is-text" @click="ocultarAyuda"
              >{{ textoAyuda }}
            </b-button>
          </p>
        </div>
        <div class="mt-4" v-if="bannerHidden">
          <p>
            <b-button type="is-text" @click="mostrarBanner"
              >¿Quieres mostrar el banner de esta página? Da click aqui
            </b-button>
          </p>
        </div>
      </section>
      <footer class="modal-card-foot"></footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getLocalParam, setLocalParam } from "@/vendors/helpers";
export default {
  name: "ModalAyuda",
  computed: {
    ...mapGetters(["getMe", "getAyudaActual"]),
    textoAyuda() {
      let title =
        this.getAyudaActual.uri == "" ? "feedback" : this.getAyudaActual.uri;
      if (this.getAyudaActual.oculto) {
        return `¿Quieres mostrar la ayuda de ${title}? Da click aqui`;
      }
      return `¿Quieres ocultar la ayuda de ${title}? Da click aqui`;
    },
    bannerHidden() {
      if (getLocalParam(`banner-${this.$route.name}`) === false) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ocultarAyuda() {
      this.$store.dispatch("toggleAyudaActual", this.getAyudaActual.id);
    },
    mostrarBanner() {
      setLocalParam(`banner-${this.$route.name}`, true);
      this.$router.go();
    }
  }
};
</script>

<style lang="scss">
.msg-hover {
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
</style>
