import GrupoCreditoApi from "@/api/grupoCredito.api";

const initialState = {
  roles: [],
};

const state = initialState;

const getters = {
  getRoles: state => state.roles,
};

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
};

const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  getRoles({ commit }) {
    return GrupoCreditoApi.getRoles(
        res => {
            commit('SET_ROLES', res.data);
            return res.data
        },
        error => {
            return error;
        }
    );
  },
}

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
