import EmpresaApi from "@/api/empresa.api";

const state = {
  cuentas_bancarias: null,
  permisos_submodulo: null
};
const getters = {
  getCuentasBancarias: state => state.cuentas_bancarias,
  getPersonasPermisosSubmodulo: state => state.permisos_submodulo
};
const mutations = {
  SET_CUENTAS_BANCARIAS(state, cuentas) {
    state.cuentas_bancarias = cuentas;
  },
  ADD_CUENTA_BANCARIA(state, cuenta) {
    state.cuentas_bancarias.push(cuenta);
  },
  EDIT_CUENTA_BANCARIA(state, cuenta) {
    state.cuentas_bancarias = state.cuentas_bancarias.map(function(item) {
      return item.id == cuenta.id ? cuenta : item;
    });
  },
  DELETE_CUENTA_BANCARIA(state, cuenta_id) {
    let filteredArray = state.cuentas_bancarias.filter(function(e) {
      return e.id !== cuenta_id;
    });
    state.cuentas_bancarias = filteredArray;
  },
  SET_PERMISOS_SUBMODULO(state, personas) {
    state.permisos_submodulo = personas;
  }
};
const actions = {
  addCuentaBancaria({ commit }, data) {
    return EmpresaApi.addCuentaBancaria(
      data,
      res => {
        commit("ADD_CUENTA_BANCARIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCuentasBancarias({ commit }) {
    return EmpresaApi.getCuentasBancarias(
      res => {
        commit("SET_CUENTAS_BANCARIAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  editCuentaBancaria({ commit }, data) {
    return EmpresaApi.editCuentaBancaria(
      data,
      res => {
        commit("EDIT_CUENTA_BANCARIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  deleteCuentaBancaria({ commit }, cuenta_id) {
    return EmpresaApi.deleteCuentaBancaria(
      cuenta_id,
      res => {
        commit("DELETE_CUENTA_BANCARIA", cuenta_id);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getPersonasPermisosSubmodulo({ commit }, submodulo_id) {
    return EmpresaApi.getPersonasPermisosSubmodulo(
      submodulo_id,
      res => {
        commit("SET_PERMISOS_SUBMODULO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  changePermisosPersonas({ commit }, data) {
    return EmpresaApi.changePermisosPersonas(
      data,
      res => {
        commit("SET_PERMISOS_SUBMODULO", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  updateSofom({ commit }, data) {
    return EmpresaApi.updateSofom(
      data,
      res => {
        commit("SET_SOFOM", res.data, { root: true });
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
