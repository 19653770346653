import config from "@/../config";
import http from "@/api/http";

export default {
  getMonedas(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/cnbv/monedas`)
      .then(onSuccess)
      .catch(onError);
  },
  getProductosFinancieros(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/productos_financieros`)
      .then(onSuccess)
      .catch(onError);
  },
  getTiposPago(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/creditos/pagos`)
      .then(onSuccess)
      .catch(onError);
  },
  getPeriodosRegulares(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/catalogos/creditos/periodos_regulares`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getTasasBase(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/tasas_base`)
      .then(onSuccess)
      .catch(onError);
  },
  getCostosExtras(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/costo-extra/con-tipo-pago`)
      .then(onSuccess)
      .catch(onError);
  },
  getTratamientoIntereses(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/catalogos/creditos/tipo_tratamiento_interes`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCreditoStatus(tipo, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/creditos/status`, {
        params: {
          activos: tipo
        }
      })
      .then(onSuccess)
      .catch(onError);
  },
  getLineaStatus(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/lineas_credito/status`)
      .then(onSuccess)
      .catch(onError);
  },
  getEjecutivos(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/ejecutivos`)
      .then(onSuccess)
      .catch(onError);
  },
  getClientes(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/clientes`)
      .then(onSuccess)
      .catch(onError);
  },
  getClientesLineasCredito(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/lineas_de_credito`)
      .then(onSuccess)
      .catch(onError);
  },
  getClientesLineasCreditoProducto(producto_financiero_id, disposicion_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/lineas_de_credito_producto/${producto_financiero_id}`, {
          params: {
            disposicion_id
          }
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDestinoRecursos(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/destino_recursos`)
      .then(onSuccess)
      .catch(onError);
  },
  getInstrumentosMonetarios(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/cnbv/inst_monetarios`)
      .then(onSuccess)
      .catch(onError);
  },
  getTiposGarantia(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/tipos_garantia`)
      .then(onSuccess)
      .catch(onError);
  },
  getTiposGarantiaPortafolio(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/catalogos/tipos_garantia_portafolio`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getTiposGarantiaLinea(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/tipos_garantia_linea`)
      .then(onSuccess)
      .catch(onError);
  },
  getAtributosTipoGarantia(tipo_garantia_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/tipo_garantia/${tipo_garantia_id}/atributos`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getFuentesFondeo(nombre, onSuccess, onError) {
    const uri = nombre ? `fuentes_de_fondeo?nombre=${nombre}` : 'fuentes_de_fondeo'; 
    return http
      .get(`${config.host}/${config.version}/personas/${uri}`)
      .then(onSuccess)
      .catch(onError);
  },
  getTiposSaldo(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/tipos_saldo`)
      .then(onSuccess)
      .catch(onError);
  },
  setOrdenPrelacionSaldos(saldos_ordenados, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/catalogos/tipos_saldo`,
        saldos_ordenados
      )
      .then(onSuccess)
      .catch(onError);
  },
  getInstitucionesFinancieras(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/catalogos/instituciones_financieras`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getSubmodulo(submodulo_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/submodulo/${submodulo_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getSubmodulos(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/submodulo`)
      .then(onSuccess)
      .catch(onError);
  },
  getParametrosPersonalizables(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/parametros_personalizables`)
      .then(onSuccess)
      .catch(onError);
  },
  getEstados(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos_sepomex/estados`)
      .then(onSuccess)
      .catch(onError);
  },
  getMunicipios(estado_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/catalogos_sepomex/estados/${estado_id}/municipios`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getLocalidades(municipio_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/localidades/inegi/localidades/${municipio_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  buscarClientesLineasCredito(nombre, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/lineas_de_credito?nombre=${nombre}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  buscarClientes(nombre, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/clientes?nombre=${nombre}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  buscarPersonas(nombre, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/buscar?nombre=${nombre}`)
      .then(onSuccess)
      .catch(onError);
  },
  getSegmentoNegocio(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/segmentos_negocio`)
      .then(onSuccess)
      .catch(onError);
  },
  getTiposRequisito(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/tipos_requisito`)
      .then(onSuccess)
      .catch(onError);
  },
  getRequisitoStatus(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/requisitos_status`)
      .then(onSuccess)
      .catch(onError);
  },
  getRoles(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/catalogos/roles`)
      .then(onSuccess)
      .catch(onError);
  },
  getGruposEconomicosInternos(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/grupos_economicos/internos`)
      .then(onSuccess)
      .catch(onError);
  },
  getGruposEconomicosCredito(nombre, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/grupos_economicos/credito${ nombre ? '?nombre=' + nombre : ''}`)
      .then(onSuccess)
      .catch(onError);
  }
};
