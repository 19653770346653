<template>
  <div>
    <div class="columns is-multiline mb-2">
      <div class="column is-12">
        <p class="text-md font-medium capitalize border-b-2">
          {{ param.nombre }}
        </p>
      </div>
      <div class="column is-6 py-0" v-if="param.tipo_dato.nombre == 'int'">
        <b-numberinput v-model="intLocal" step="1" @input="setLocal" />
      </div>
      <div class="column is-6 py-0" v-if="param.tipo_dato.nombre == 'int'">
        {{ param.server_value ? param.server_value.data : "Sin definir" }}
      </div>
      <div class="column is-6 py-0" v-if="param.tipo_dato.nombre == 'array'">
        <ul v-if="param.local_value">
          <li
            v-for="(paramlocal, index) in JSON.parse(param.local_value)"
            :key="index"
          >
            <ul class="card has-border-radius p-1">
              <li
                v-for="(value, key) in paramlocal"
                :key="key"
                class="has-text-grey-dark"
              >
                {{ key }}: {{ value }}
              </li>
            </ul>
          </li>
        </ul>
        <p class="has-text-grey-dark" v-if="!param.local_value">
          Sin definir
        </p>
      </div>
      <div class="column is-6 py-0" v-if="param.tipo_dato.nombre == 'array'">
        <ul v-if="param.server_value">
          <li
            v-for="(paramserver, index) in JSON.parse(
              param.server_value.data
                .split('\\')
                .join('')
                .slice(1, -1)
            )"
            :key="index"
          >
            <ul class="card has-border-radius p-1">
              <li
                v-for="(value, key) in paramserver"
                :key="key"
                class="has-text-grey-dark"
              >
                {{ key }}: {{ value }}
              </li>
            </ul>
          </li>
        </ul>
        <p class="has-text-grey-dark" v-if="!param.server_value">Sin definir</p>
      </div>
    </div>
  </div>
</template>

<script>
import { setLocalParam } from "@/vendors/helpers";

export default {
  name: "Parametros",
  props: {
    param: Object
  },
  data() {
    return {
      value: null
    };
  },
  computed: {
    intLocal: {
      get: function() {
        return parseInt(this.param.local_value);
      },
      set: function(newValue) {
        this.param.local_value = newValue;
      }
    }
  },
  methods: {
    /*
    Función para guardar algún dato extra en localstorage
    */
    setLocal(value) {
      setLocalParam(this.param.nombre, value);
    }
  }
};
</script>
