<template>
  <ValidationProvider
    :rules="rules != 'none' ? rules : ''"
    v-slot="{ errors, valid }"
  >
    <b-field
      :type="{
        'is-danger': errors[0],
        'is-success': valid && rules != 'none'
      }"
      :label="label"
      :message="errors"
      :custom-class="labelClass"
    >
      <b-datepicker
        :position="position"
        :placeholder="placeholder"
        icon="calendar-alt"
        v-model="innerValue"
        :date-formatter="formatter"
        @input="tellParent"
        :min-date="minDate"
        :max-date="maxDate"
        :range="range"
        :append-to-body="appendToBody"
        :month-names="[
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre'
        ]"
        editable
        :disabled="disabled"
        :date-parser="parser"
      />
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "validDatepicker",
  components: {
    ValidationProvider
  },
  props: {
    rules: String,
    label: String,
    placeholder: String,
    value: null,
    position: String,
    minDate: Date,
    maxDate: Date,
    appendToBody: Boolean,
    labelClass: String,
    range: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    formatter: Function
  },
  methods: {
    dateFormatter(date) {
      return new Date(date)
        .toISOString()
        .replace(/T.*/, "")
        .split("-")
        .reverse()
        .join("-");
    },
    tellParent(newVal) {
      this.$emit("directInput", newVal);
    },
    parser(date) {
      return new Date(date.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
    }
  },
  data: () => ({
    innerValue: null
  }),
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (typeof this.formatter == undefined) {
      this.formatter = this.dateFormatter;
    }
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
