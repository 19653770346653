<template>
  <div class="busqueda-div -mt-12 md:mr-4">
    <div class="card has-background-white has-border-radius shadow-none shadow">
      <div class="text-right mb-4">
        <b-tooltip label="Cerrar" type="is-dark">
          <b-button
            icon-left="times-circle"
            class="cursor-pointer"
            @click="cerrarBusqueda"
          />
        </b-tooltip>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <div class="search-container">
            <b-field>
              <b-input
                id="buscador"
                placeholder="Buscar..."
                type="search"
                v-model="search"
                icon-pack="fas"
                icon-right="search"
                class="search-input"
                icon-right-clickable
                @icon-right-click="searchValue(search)"
                @keyup.enter.native="searchValue(search)"
              ></b-input>
            </b-field>
            <div class="flex overflow-x-auto">
              <span
                v-for="filter in filters"
                :key="filter.key"
                class="tag is-primary-light mx-1 cursor-pointer"
                :class="{ active: filters_active.includes(filter.key) }"
                @click="setFilters(filter.key)"
                >{{ filter.name }}</span
              >
            </div>
          </div>
          <div
            class="text-left mt-3 busqueda-container"
            v-if="
              Object.keys(busqueda['id']).length > 0 ||
                busqueda['nombre'].length > 0
            "
          >
            <Loading v-if="loading" description="Cargando" />
            <p>Mejores resultados con</p>
            <p class="text-3xl font-bold capitalize mb-2">
              "{{ getBusquedaNombre.split(";").join(", ") }}"
            </p>
            <tabs v-model="activeTab" v-if="!hidetabs" :tabs="tabs" />
            <!-- POR ID -->
            <div v-if="activeTab == 0" class="mt-4 md:mt-0">
              <div
                v-for="coincidencia_key in Object.keys(busqueda['id'])"
                :key="coincidencia_key"
              >
                <div class="columns is-multiline overflow-y-auto px-3">
                  <p class="text-2xl font-light mb-0">
                    {{ filters.find(x => x.key == coincidencia_key).name }}
                  </p>
                  <div class="column is-12 mb-4 -mt-3">
                    <ul class="ml-4">
                      <li
                        v-for="item in busqueda['id'][coincidencia_key]"
                        :key="item.id"
                        class="py-2"
                      >
                        <buscador-card
                          :tipo="coincidencia_key"
                          :item="item"
                          @active="setActive"
                          :active="active"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- POR NOMBRE -->
            <div v-if="activeTab == 1">
              <p class="text-2xl font-light mb-0">
                {{ filters.find(x => x.key == "persona").name }}
              </p>
              <div
                v-for="(coincidencias, index) in busqueda['nombre']"
                :key="index"
              >
                <div class="columns is-multiline overflow-y-auto px-3">
                  <div class="column is-12 mb-4 -mt-3">
                    <ul class="ml-4">
                      <li class="py-2">
                        <div class="column is-12 result my-1">
                          <b-collapse
                            class="search-collapse"
                            animation="slide"
                            :aria-id="'accordion' + coincidencias['persona'].id"
                            :open="false"
                          >
                            <div
                              slot="trigger"
                              slot-scope="props"
                              role="button"
                              :aria-controls="
                                'accordion' + coincidencias['persona'].id
                              "
                            >
                              <buscador-card
                                tipo="persona"
                                class="z-1"
                                :item="coincidencias['persona']"
                                @active="setActive"
                                :active="active"
                                :icon="
                                  props.open ? 'chevron-up' : 'chevron-down'
                                "
                              />
                            </div>
                            <div
                              class="card has-border-radius mt-3 shadow-none z-0 has-background-white"
                            >
                              <b-tabs
                                size="is-small"
                                type="is-boxed"
                                :vertical="isTabVertical"
                              >
                                <b-tab-item
                                  v-for="grupos in Object.keys(
                                    coincidencias
                                  ).slice(1)"
                                  :key="grupos"
                                  :label="
                                    filters.find(x => x.key == grupos).name
                                  "
                                >
                                  <ul class="ml-4">
                                    <li
                                      v-for="(item,
                                      index_item) in coincidencias[grupos]"
                                      :key="item.id"
                                      class="py-2"
                                    >
                                      <buscador-card
                                        :tipo="grupos"
                                        :item="item"
                                        :hideSub="true"
                                        :class="{
                                          'border-b':
                                            coincidencias[grupos].length - 1 !=
                                            index_item
                                        }"
                                        @active="setActive"
                                        :active="active"
                                      />
                                    </li>
                                  </ul>
                                </b-tab-item>
                              </b-tabs>
                            </div>
                          </b-collapse>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="mt-2">
            <empty-state
              type="svg"
              imagen="/images/buscador_placeholder.svg"
              imgclass="mb-4"
              :vertical="true"
              :titulo="
                'No hay resultados con &quot;' + getBusquedaNombre + '&quot;'
              "
              subtitulo="Prueba con otra palabra o número"
            />
          </div>
        </div>
        <div
          class="column is-5 h-full mt-24 sticky-sidebar"
          v-if="
            Object.keys(busqueda['id']).length > 0 ||
              busqueda['nombre'].length > 0
          "
          :class="{
            'hidden md:block': active == null
          }"
        >
          <div class="card has-border-radius shadow-none h-auto">
            <empty-state
              v-if="active == null"
              class="pt-16 pb-8 has-background-white-ter has-border-radius"
              :vertical="true"
              imagen="/images/buscador.png"
              imgclass="w-2/5 mb-4"
              titulo="Selecciona un resultado"
              subtitulo="para ver el detalle"
            />
            <content-preview
              v-else
              class="has-background-white-ter has-border-radius py-5"
              :info="active.item"
              :tipo="active.tipo"
              :clase="'has-background-white-ter'"
              :id="active.item.id"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmptyState from "@/components/generals/EmptyState";
import BuscadorCard from "@/components/cards/BuscadorCard";
import ContentPreview from "@/components/generals/ContentPreview";
import Tabs from "@/components/generals/Tabs";
import Loading from "@/components/layout/Loading";
import { mapGetters } from "vuex";
// import { debounce } from "@/vendors/helpers";

export default {
  name: "Busqueda",
  data() {
    return {
      search: null,
      loading: false,
      active: null,
      activeTab: 0,
      hidetabs: false,
      filters: [
        {
          key: "credito",
          name: "Disposiciones"
        },
        {
          key: "cuentaCP",
          name: "Cuentas cobrar pagar"
        },
        {
          key: "garantia",
          name: "Garantías"
        },
        {
          key: "linea",
          name: "Líneas de crédito"
        },
        // {
        //   key: "pago",
        //   name: "Pagos"
        // },
        {
          key: "persona",
          name: "Personas"
        }
      ],
      filters_active: []
    };
  },
  components: {
    EmptyState,
    BuscadorCard,
    ContentPreview,
    Tabs,
    Loading
  },
  computed: {
    ...mapGetters(["getBusquedaNombre", "getBusqueda"]),
    tipo() {
      return this.getBusqueda.tipo;
    },
    isTabVertical() {
      if (
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 768
      ) {
        return true;
      }
      return false;
    },
    // Le da formato y agrupa la petición del API para mostrar las coincidencias
    busqueda() {
      if (this.getBusqueda != null) {
        let busqueda_id = {
          persona: [],
          credito: [],
          cuentaCP: [],
          garantia: [],
          linea: [],
          // pago: []
        };
        let busqueda_nombre = [];
        this.getBusqueda.forEach(coincidencia => {
          if (coincidencia.tipo == "id") {
            Object.keys(coincidencia)
              .slice(0, -1)
              .forEach(x => {
                busqueda_id[x].push(coincidencia[x]);
              });
          }
          if (coincidencia.tipo == "persona") {
            Object.keys(coincidencia)
              .slice(0, -1)
              .forEach(x => {
                busqueda_nombre.push(coincidencia[x]);
              });
          }
        });
        busqueda_id = Object.keys(busqueda_id)
          .filter(x => busqueda_id[x].length > 0)
          .reduce((obj, key) => {
            return {
              ...obj,
              [key]: busqueda_id[key]
            };
          }, {});
        return {
          id: busqueda_id,
          nombre: busqueda_nombre
        };
      }
      return [];
    },
    // Divide las respuestas de la API en caso de buscar un nombre y un folio al mismo tiempo
    tabs() {
      return [
        {
          label: "Por Folio [" + Object.keys(this.busqueda["id"]).length + "]",
          disabled: Object.keys(this.busqueda["id"]).length == 0
        },
        {
          label: "Por Personas [" + this.busqueda["nombre"].length + "]",
          disabled: this.busqueda["nombre"].length == 0
        }
      ];
    }
  },
  methods: {
    setActive(data) {
      this.active = data;
    },
    /* 
      Agrega un nuevo filtro al buscador
      @filter(Object): nuevo filtro
    */
    setFilters(filter) {
      let repeated_index = this.filters_active.findIndex(x => x == filter);
      if (repeated_index >= 0) {
        this.filters_active.splice(repeated_index, 1);
        this.$store.dispatch("buscar", {
          val: this.getBusquedaNombre,
          filtros: this.filters_active
        });
        return;
      }
      this.filters_active.push(filter);
      this.$store.dispatch("buscar", {
        val: this.getBusquedaNombre,
        filtros: this.filters_active
      });
    },
    cerrarBusqueda() {
      this.$store.dispatch("clearBusqueda");
    },
    /* 
      Función que activa el buscador de capvital, en caso de ser el valor
      buscado "9 3/4", se activa el modo de harry potter
      @val (String): valor a buscar
    */
    searchValue(val) {
      this.active = null;
      if (val == this.getBusquedaNombre) {
        return;
      }
      if (val && val.length > 2) {
        this.loading = true;
        this.$store
          .dispatch("buscar", {
            val,
            filtros: this.filters_active
          })
          .then(() => {
            this.loading = false;
          });
      }
      if (val === "9 3/4") {
        this.$store.dispatch("setHp", "hp");
        this.$buefy.snackbar.open({
          message:
            "<span class='font-bold'>¡Has activado el tipo de cambio del \
            mundo de harry potter! puedes obtener mas información en la \
            siguiente liga: <a target='_blank' class='underline' \
            href='https://harrypotter.fandom.com/es/wiki/Dinero_del_mundo_m%C3%A1gico'>\
            aquí</a></span></br>1 galeón = $126.71, 1 sickle = $6.57, 1 knut = 0.23 \
            </br><small>los montos son todos calculados en base a MXN por lo que pueden \
            existir algunos errores.</small>",
          type: "is-danger",
          position: "is-bottom",
          indefinite: true,
          actionText: "¡Regresar!",
          onAction: () => {
            this.search = null;
            this.$store.dispatch("setHp", "currency");
            this.$buefy.toast.open({
              duration: 4000,
              message: `¡De vuelta a las finanzas muggle!`,
              position: "is-top-right",
              type: "is-info"
            });
          }
        });
      }
    },
  },
  watch: {
    busqueda: {
      immediate: true,
      handler(val) {
        if (Object.keys(val["id"]).length == 0) {
          this.activeTab = 1;
          this.hidetabs = true;
        }
        if (val["nombre"].length == 0) {
          this.activeTab = 0;
          this.hidetabs = true;
        }
        if (Object.keys(val["id"]).length > 0 && val["nombre"].length > 0) {
          this.hidetabs = false;
        }
      }
    },
  },
  created() {
    this.search = this.getBusquedaNombre;
  }
};
</script>
<style lang="scss">
.busqueda-container {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.search-container {
  position: sticky;
  top: 4.9rem;
  padding-top: 0.5rem;
  z-index: 1;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  background-color: var(--white);
  padding-bottom: 0.5rem;
}
.results-container .result {
  padding-top: 0;
  border-radius: 11px;
  border: 1px solid lightgray;
}
.search-collapse .collapse-content {
  width: calc(100% + 1.5rem);
  margin-left: -0.75rem;
  .tabs a {
    padding: 1rem 0;
    padding-left: 0.5rem;
  }
}
</style>
