import HelpersApi from "@/api/helpers.api";

const state = {
  preview: null,
  error: null,
  previewKeys: {
    tipo: null,
    id: null
  }
};
const getters = {
  getPreview: state => state.preview,
  getPreviewKeys: state => state.previewKeys
};
const mutations = {
  SET_PREVIEW(state, preview) {
    state.preview = preview;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_PREVIEW_KEYS(state, keys) {
    state.previewKeys = keys;
  }
};
const actions = {
  getPreview({ commit }, data) {
    return HelpersApi.getPreview(
      data,
      res => {
        commit("SET_PREVIEW", res.data);
        commit("SET_PREVIEW_KEYS", data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  closePreview({ commit }) {
    commit("SET_PREVIEW_KEYS", {
      tipo: null,
      id: null
    });
  },
  sendError({ commit }, data) {
    return HelpersApi.sendError(
      data,
      res => {
        commit("SET_ERROR", data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
