import config from "@/../config";
import http from "@/api/http";

export default {
  getRoles(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/grupo_credito/roles`
      )
      .then(onSuccess)
      .catch(onError);
  },
  rompimiento(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/grupo_credito/rompimiento/${data.disposicion_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  }
}