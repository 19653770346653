var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rounded-full text-center",class:[
    {
      'p-2 w-10': _vm.size == 'small',
      'py-4 w-56px': _vm.size != 'small',
      'flex items-center justify-center': _vm.tipo == 1,
      direction: _vm.direction
    },
    _vm.color
  ]},[(_vm.tipo == 1)?_c('b-icon',{attrs:{"icon":"building"}}):_c('span',[_vm._v(" "+_vm._s(_vm.initials)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }