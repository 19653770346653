import config from "@/../config";
import http from "@/api/http";

export default {
  getDetallesPersona(persona_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/${persona_id}/detalles`)
      .then(onSuccess)
      .catch(onError);
  },
  getAcreditadosActivosSinFondeo(onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/acreditados_activos_sin_fondeo`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getCreditosConSaldoPersona(persona_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/${persona_id}/creditos/con_saldo`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getGarantias(persona_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/${persona_id}/garantias`)
      .then(onSuccess)
      .catch(onError);
  },
  getGarantiasGrupo(grupo_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/${grupo_id}/garantias_grupo`)
      .then(onSuccess)
      .catch(onError);
  },
  getRelaciones(persona_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/${persona_id}/relaciones_raw`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getRelacionesGrupo(grupo_credito_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/${grupo_credito_id}/relaciones_grupo`
      )
      .then(onSuccess)
      .catch(onError);
  },
  relacionar(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/personas/${data.persona1}/relacionar/${data.persona2}`,
        {
          roles_id: data.roles_id
        }
      )
      .then(onSuccess)
      .catch(onError);
  },
  getPermisosRutas(rutas, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/me/validar_permiso/rutas`, {
        params: {
          rutas: rutas
        }
      })
      .then(onSuccess)
      .then(onError);
  },
  getCuentasBancarias(persona_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/personas/${persona_id}/cuentas_bancarias`)
      .then(onSuccess)
      .catch(onError);
  },
  buscarEjecutivo(nombre, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/buscar_ejecutivo`, {
        params: {
          por_pagina: 20,
          nombre
        }
      })
      .then(onSuccess)
      .catch(onError);
  },
  buscarCliente(nombre, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/personas/buscar_cliente`, {
        params: {
          por_pagina: 20,
          nombre
        }
      })
      .then(onSuccess)
      .catch(onError);
  },
};
