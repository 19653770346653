<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <header class="modal-card-head has-background-grey-lighter">
        <p class="modal-card-title has-text-grey-darker">
          Cambiar de sucursal
        </p>
      </header>
      <section class="modal-card-body has-background-white">
        <valid-auto-complete
          rules="required"
          label="Sucursal"
          placeholder="Buscar..."
          v-model="sucursal"
          :data="sucursales"
          @select="setSucursal"
          show="nombre"
          :expanded="true"
        />
      </section>
      <footer class="modal-card-foot has-background-grey-lighter justify-end">
        <button class="button" @click="$parent.close()">
          Cancelar
        </button>
        <b-button
          type="is-primary"
          @click="validate().then(result => cambiarSucursal(result))"
        >
          Cambiar sucursal
        </b-button>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";
import ValidAutoComplete from "@/components/form/ValidAutoComplete";
import sessionApi from "@/api/session.api";

export default {
  name: "CambiarSucursal",
  components: {
    ValidationObserver,
    ValidAutoComplete
  },
  data() {
    return {
      sucursal: null,
      sucursal_id: null
    };
  },
  methods: {
    cambiarSucursal(result) {
      if (result) {
        sessionApi.cambiarSucursal(
          this.sucursal_id,
          result => {
            if (result) {
              location.reload();
            }
          },
          error => error
        );
      }
    },
    setSucursal(val) {
      this.sucursal_id = val.id;
    }
  },
  computed: {
    ...mapGetters(["getSofom", "getMe"]),
    sucursales() {
      return this.getMe.sucursales;
    }
  },
  mounted() {
    this.sucursal = this.getMe.sucursal_activa.nombre;
    this.sucursal_id = this.getMe.sucursal_activa.id;
  }
};
</script>

<style lang="scss">
.msg-hover {
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
</style>
