const state = {
  loading: 0
};
const getters = {
  loading: state => state.loading
};
const mutations = {
  START_LOADING(state) {
    state.loading++;
  },
  FINISH_LOADING(state) {
    state.loading--;
  },
  SET_CERO(state) {
    state.loading = 0;
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations
};
