import config from "@/../config";
import http from "@/api/http";

export default {
  getEjemplo(params, data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/reportes3/reporte_global/${data}?${params}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getModelos(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes3/modelos`)
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefUsers(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/info/users`)
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefQuejas(params,onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/info/quejas`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefMediosRecepcion(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/medios_recepcion`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefNivelesAtencion(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/niveles_atencion`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefProductos(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/productos`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefCausas(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/causas`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefEstados(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/estados`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefCodigosPostales(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/codigos_postales`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefMunicipios(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/municipios`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefColonias(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/colonias`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefSentidoResolucion(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/catalogo/sentido_resolucion`, {params})
      .then(onSuccess)
      .catch(onError);
  },
  postCondusefRedeco(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/reportes/condusef/redeco`, data)
      .then(onSuccess)
      .catch(onError);
  },
  postCondusefReuneConsulta(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/reportes/condusef/reune_consulta`, data)
      .then(onSuccess)
      .catch(onError);
  },
  postCondusefReuneReclamacion(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/reportes/condusef/reune_reclamacion`, data)
      .then(onSuccess)
      .catch(onError);
  },
  getCondusefConfigService(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/reportes/condusef/conf/${params.tipo_reporte}`)
      .then(onSuccess)
      .catch(onError);
  }
};
