<template>
  <div class="has-background-white has-border-radius modal-datos-extras">
    <header class="modal-card-head">
      <div class="modal-card-title columns">
        <div class="column flex items-center">
          <b-button
            icon-left="arrow-left"
            type="is-primary"
            outlined
            class="mr-3 cursor-pointer"
            v-if="active_file > 0"
            @click="atrasActivo()"
          />
          {{ files[active_file].nombre }}
        </div>
        <div class="column text-right has-text-grey-light">
          {{ active_file + 1 }} de {{ files.length }}
        </div>
      </div>
    </header>
    <div class="modal-body p-3">
      <validation-observer
        ref="observer"
        v-slot="{ validate }"
        class="w-full text-left"
      >
        <div v-for="(file, index) in files" :key="file.id">
          <div class="columns is-multiline" v-if="active_file == index">
            <div class="column is-12">
              <valid-input
                rules="required"
                label="Descripción"
                placeholder="Descripción..."
                v-model="descripcion"
                type="text"
                :expanded="true"
              />
            </div>
            <div class="column is-12">
              <valid-datepicker
                label="Fecha de expiración"
                placeholder="selecciona una fecha..."
                v-model="fecha_entrega"
                position="is-top-right"
                size="is-small"
              />
            </div>
            <div class="column is-12">
              <b-button
                type="is-primary"
                expanded
                @click="validate().then(result => actualizarDatosDoc(result))"
              >
                {{ nombreBoton }}
              </b-button>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import ValidInput from "@/components/form/ValidInput";
import { mapGetters } from "vuex";

export default {
  name: "DatosExtras",
  data() {
    return {
      fecha_entrega: null,
      descripcion: null,
      active_file: 0,
      files_to_update: []
    };
  },
  props: {
    files: Array,
    carpeta: String
  },
  components: {
    ValidDatepicker,
    ValidInput,
    ValidationObserver
  },
  methods: {
    /*
      función para despues de subir los archivos, llenar los datos de los mismos y cuando se llega al ultimo
      se hace la petición a API para guardar los archivos nuevos con su info.
    */
    actualizarDatosDoc(res) {
      if (!res) {
        return;
      }
      this.files_to_update.push({
        id: this.files[this.active_file].id,
        fecha_entrega: this.$moment(this.fecha_entrega).format("YYYY-MM-DD"),
        descripcion: this.descripcion,
        carpeta: this.carpeta
      });
      if (this.active_file + 1 < this.files.length) {
        this.active_file++;
        this.descripcion = null;
        this.fecha_entrega = null;
      } else {
        this.$store
          .dispatch("files/guardarDatos", this.files_to_update)
          .then(() => {
            this.$parent.close();
          });
      }
    },
    /*
      Cuando se suben mas de un documento a la vez, es posible regresar al documento anterior para modificar sus datos
    */
    atrasActivo() {
      this.active_file--;
      this.descripcion = this.files_to_update[this.active_file].descripcion;
      this.fecha_entrega = this.$moment(
        this.files_to_update[this.active_file].fecha_entrega
      ).toDate();
      this.files_to_update.splice(this.active_file, 1);
    }
  },
  computed: {
    ...mapGetters(["getSofom"]),
    nombreBoton() {
      if (this.active_file + 1 == this.files.length) {
        return "Actualizar documentos";
      } else {
        return "Continuar";
      }
    }
  }
};
</script>

<style lang="scss">
.modal-datos-extras {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
}
</style>
