<template>
  <div
    class="inline-block border-b-2 border-dashed preview-container relative"
    :class="border"
  >
    <span
      class="inline-block dashed cursor-pointer"
      :class="color"
      @click.prevent.stop="openPreview"
    >
      <slot></slot>
    </span>
    <portal
      to="previewContainer"
      v-if="
        (getPreview != null &&
          getPreviewKeys.tipo == tipo &&
          getPreviewKeys.id == id) ||
          tipo == 'saldo_vigente' ||
          tipo == 'saldo_vencido'
      "
    >
      <content-preview
        :key="previewKey"
        :info="getPreview"
        :tipo="tipo"
        :id="id"
        :saldos="saldos"
        :moneda="moneda"
        :pasivo="pasivo"
      />
    </portal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ContentPreview from "@/components/generals/ContentPreview";

export default {
  name: "Preview",
  props: {
    tipo: String,
    id: Number,
    color: {
      type: String,
      default: "has-text-info"
    },
    border: {
      type: String,
      default: "has-border-info"
    },
    saldos: [Object, null],
    moneda: String,
    pasivo: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentPreview
  },
  data() {
    return {
      previewKey: 0
    };
  },
  computed: {
    ...mapGetters("helpers", ["getPreview", "getPreviewKeys"])
  },
  methods: {
    /*
      Función que se encarga de abrir ContentPreview con los datos obtenidos de API
    */
    openPreview() {
      this.previewKey += 1;
      if (
        this.tipo == this.getPreviewKeys.tipo &&
        this.id == this.getPreviewKeys.id
      ) {
        return;
      }
      if (this.tipo != "saldo_vigente" && this.tipo != "saldo_vencido") {
        this.$store.dispatch("helpers/getPreview", {
          tipo: this.tipo,
          id: this.id
        });
      }
    }
  }
};
</script>

<style lang="scss">
.preview-container {
  position: relative;
}
</style>
