<template>
  <b-sidebar
    v-if="getMe"
    class="m-0 p-0"
    position="static"
    mobile="fullwidth"
    :reduce="!getSidebarOpen"
    id="sidebar"
    :open="sidebarOpenMbl"
  >
    <div class="h-screen">
      <div class="block">
        <b-button
          icon-left="times"
          class="absolute top-0 right-0 mt-4 mr-3 md:hidden block z-10 has-text-grey-darker"
          @click="closeSidebarMobile"
          type="is-text"
        />
        <transition name="fade">
          <b-image
            v-if="!getSidebarOpen"
            src="/images/logo_white_symbol.png"
            alt="Capvital logo"
            class="p-2 mt-2"
          />
          <b-image
            v-else
            src="/images/logo_full_white.png"
            class="img-full mr-12"
            alt="Capvital logo"
          />
        </transition>
        <dropdown-user
          class="block md:hidden"
          :config="config"
          :sofom="getSofom"
          :me="getMe"
        />
      </div>
      <b-menu class="is-custom-mobile">
        <b-menu-list>
          <b-menu-item
            v-for="(modulo, index) in modulosActivos"
            :key="modulo.id"
            :icon="modulo.icon"
            :active="$route.meta.modulo == modulo.nombre"
            :expanded="sectionActive == modulo.id && getSidebarOpen"
            @click="changeActive(modulo)"
            :id="'sidebar' + index"
            :class="{
              reportes: modulo.nombre == 'Reportes',
              'modulo-active': sectionActive == modulo.id,
              'py-4': !getSidebarOpen
            }"
          >
            <template slot="label">
              <span v-if="getSidebarOpen">{{ modulo.nombre }}</span>
              <b-tooltip
                :label="modulo.nombre"
                class="tooltip-sidebar"
                v-if="!getSidebarOpen"
                position="is-right"
                type="is-dark"
              />
            </template>
            <b-menu-item
              :label="submodulo.nombre"
              v-for="submodulo in modulo.submodulos"
              :key="submodulo.id"
              :active="$route.meta.submodulo == submodulo.nombre"
              @click="goToSubmodule(submodulo)"
            />
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
</template>

<script>
import { mapGetters } from "vuex";
import DropdownUser from "@/components/layout/DropdownUser";
import config from "@/../config";
import hotkeys from "hotkeys-js";

export default {
  data() {
    return {
      sectionActive: null,
      hiddenmenus: false,
      config: null
    };
  },
  components: {
    DropdownUser
  },
  watch: {
    getSidebarOpen: function(val) {
      this.checkSidebarSize(val);
    }
  },
  methods: {
    checkSidebarSize(sidebarStatus) {
      if (sidebarStatus == true) {
        if (this.$screen.width >= 768) {
          document.getElementById("sidebarContainer").style.width = "20%";
          document.getElementById("sidebar").style.width = "18%";
          document.querySelector("#topNavbarContainer .full").style.right =
            "-20%";
        } else {
          document.getElementById("sidebarContainer").style.width = "100%";
          document.getElementById("sidebar").style.width = "100%";
          document.querySelector("#topNavbarContainer .mobile").style.right =
            "-80%";
        }
      } else {
        if (this.$screen.width >= 768) {
          document.getElementById("sidebarContainer").style.width = "6%";
          document.getElementById("sidebar").style.width = "5.3%";
        } else {
          document.getElementById("sidebarContainer").style.width = "0";
          document.getElementById("sidebar").style.width = "7%";
        }
        document.querySelector("#topNavbarContainer .full").style.right = "0";
      }
    },
    /*
      Función que cambia la ruta a la del submodulo, comprueba si es en vue o angularjs
      @submodule(Object)
    */
    goToSubmodule(submodule) {
      if (submodule.id == this.sectionActive) {
        return;
      }
      if (submodule.framework == "angularjs") {
        window.location = process.env.VUE_APP_CLIENT + submodule.path;
      } else {
        this.$router.replace(submodule.path);
        this.$store.dispatch("setSidebarOpen", false);
      }
    },
    /*
      Cambia el modulo activo y crea un emit al checkOpen el cual compruba si el modulo tiene o no submodulos
      @modulo(Object)
    */
    changeActive(modulo) {
      this.$store.dispatch("setSidebarOpen", true);
      this.sectionActive = modulo.id;
      this.$emit("checkOpen", modulo);
    },
    //Cierra el sidebar cuando esta en movil
    closeSidebarMobile() {
      this.$store.dispatch("setSidebarOpen", false);
      this.$store.dispatch("setSidebarOpenMobile", false);
    }
  },
  computed: {
    ...mapGetters([
      "getMe",
      "getSidebarOpen",
      "getSidebarOpenMobile",
      "getSofom"
    ]),
    //Filtra y muestra solo los modulos y submodulos activos
    modulosActivos() {
      let modulos = JSON.parse(JSON.stringify(this.getMe.modulos));
      modulos = modulos.filter(modulo => {
        return modulo.activo == 1;
      });
      modulos.forEach((modulo, index) => {
        modulos[index].submodulos = modulo.submodulos.filter(
          x => x.activo == 1
        );
      });
      return modulos;
    },
    // Comprueba si es desktop o movil para mostrar sidebar
    sidebarOpenMbl() {
      if (this.$screen.width >= 768) {
        return true;
      }
      return this.getSidebarOpenMobile;
    }
  },
  mounted() {
    this.modulosActivos.forEach((x, index) => {
      hotkeys(`ctrl+shift+${index + 1}`, () => {
        document.getElementById("sidebar" + (index + 1)).click();
      });
    });
    window.onresize = () => {
      this.closeSidebarMobile();
      this.checkSidebarSize(this.getSidebarOpen);
    };
  },
  created() {
    this.config = config;
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
#sidebarContainer {
  z-index: 2;
  transition: width 150ms ease-out;
  width: 6%;
  flex: none;
  ::-webkit-scrollbar {
    width: px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 11px;
    border-radius: 11px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 11px;
    border-radius: 11px;
    background: $primary-darker;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    &:window-inactive {
      background: $primary-darker;
    }
  }
}
.b-sidebar {
  position: fixed;
  z-index: 1;
  height: 100vh;
  width: 5.3%;
  top: 0;
  left: 0;
  .tooltip-sidebar {
    width: 40px;
    height: 40px;
    position: absolute;
    display: block !important;
    padding-left: 3rem;
    padding-top: 0.4rem;
  }
  .sidebar-content {
    background: transparent !important;
    box-shadow: none !important;
    .menu.is-custom-mobile {
      height: calc(100% - 200px);
      > ul {
        height: 85vh;
        overflow-y: auto;
        li {
          a {
            span:nth-child(2) {
              text-align: left;
              font-weight: 300;
              color: white;
              opacity: 0.68;
              font-size: 24px;
              transition: all 0.1s ease-in-out;
            }
            &.is-active {
              background-color: transparent !important;
              span:nth-child(2) {
                opacity: 1;
                font-size: 26px;
                font-weight: 400;
              }
            }
            &:hover {
              span:nth-child(2) {
                opacity: 1;
                font-size: 25px;
              }
              background-color: transparent !important;
            }
          }
          ul {
            scrollbar-width: thin;
            text-align: left;
            border-left: 1px solid transparent;
            span {
              opacity: 0.8;
              color: white;
              transition: all 0.2s ease-out;
            }
            span:hover {
              opacity: 1;
            }
            li a.is-active {
              font-weight: 600;
              border-radius: 11px;
              background-color: rgba(0, 0, 0, 0.1) !important;
            }
          }
        }
      }
      .reportes {
        ul {
          height: 25rem !important;
          overflow-y: scroll;
        }
      }
      .hidden-menus {
        ul {
          display: none;
        }
      }
    }
  }
}
.b-sidebar {
  .sidebar-content {
    width: 60%;
    &:not(.is-mini) {
      .menu-list {
        .icon {
          display: none;
        }
        a {
          text-align: left;
          padding-left: 2rem;
        }
      }
      .modulo-active {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 11px;
        margin-left: 1.5rem;
        padding: 0.2rem 0;
        > a {
          padding-left: 0.5rem;
        }
        > ul {
          padding-left: 0;
          li a {
            padding-left: 0.5rem;
          }
        }
      }
    }
    &.is-mini {
      width: 100% !important;
      &:not(.is-mini-expand),
      &.is-mini-expand:not(:hover) {
        .menu-list {
          > li {
            padding-bottom: 0.7rem;
            > a {
              transition: all 0.3s ease-in-out;
              width: 2.6rem;
              height: 2.6rem;
              margin: 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.5rem;
              border-radius: 12px;
              .icon {
                transform: scale(1.4);
                color: $white;
              }
              &.is-active {
                .icon {
                  color: var(--sidebar-primary);
                }
                background-color: white !important;
                & ~ ul {
                  display: none;
                }
              }
              span:nth-child(2) {
                display: none;
              }
              &:hover {
                background-color: rgba(255, 255, 255, 0.6) !important;
                .icon {
                  color: var(--sidebar-primary);
                }
              }
            }
            ul {
              display: none;
              padding-left: 0;
              li {
                a {
                  display: inline-block;
                }
              }
            }
          }
        }
        .menu-label:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
    &.is-mini-expand:hover {
      img {
        width: 80%;
        margin: 0 auto;
      }
      .menu-list {
        margin-top: 1rem;
        text-align: left;
        padding-left: 2.5rem;
        > li {
          > a {
            .icon {
              display: none !important;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  #main {
    width: 92% !important;
  }
  #sidebarContainer {
    overflow-y: hidden;
    width: 0;
    img {
      padding-top: 1rem;
      width: 18rem;
      margin: 0 auto;
    }
  }
  .b-sidebar {
    position: relative;
    width: 7%;
    .sidebar-content {
      &.is-mini-mobile {
        width: 100% !important;
        &:hover {
          width: 60% !important;
        }
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover) {
          .menu-list {
            li {
              a {
                .icon {
                  transform: scale(1);
                  color: $white;
                }
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
