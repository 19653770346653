<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Configuración de Capvital</p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </header>
      <section class="modal-card-body has-background-white pt-0">
        <b-tabs vertical>
          <b-tab-item label="Parametros" icon="key" :key="reload">
            <div class="columns mb-0">
              <div class="column is-6">
                <p class="text-xl font-bold">Configuración local</p>
              </div>
              <div class="column is-6">
                <p class="text-xl font-bold">Configuración en la nube</p>
              </div>
            </div>
            <div v-for="param in params" :key="param.id">
              <parametros :param="param" />
            </div>
            <b-field class="mt-8" label="Copiar configuración local">
              <b-input
                :value="config"
                icon-right="copy"
                id="copyconfig"
                @click.native="copyConfig"
              />
            </b-field>
            <b-field class="mt-4 mb-8" label="Pegar configuración">
              <b-input
                v-model="pasteConfig"
                icon-right="paste"
                @input="saveConfig"
              />
            </b-field>
            <div class="columns">
              <div class="column">
                <b-button class="is-primary" outlined @click="serverToLocal"
                  >Usar configuración de la nube</b-button
                >
              </div>
              <div class="column">
                <b-button class="is-primary" @click="localToServer"
                  >Guardar configuración local en la nube</b-button
                >
              </div>
            </div>
          </b-tab-item>
          <b-tab-item label="Cache" icon="hdd">
            <div class="ml-2">
              <p>
                Al decidir almacenar los siguientes datos en cache,
              </p>
              <p>
                la velocidad de carga de capvital aumentará, pero es probable
                que
              </p>
              <p>
                al hacer un cambio a los datos de la empresa o de tu usuario,
              </p>
              <p>
                estos no se vean reflejados hasta que limpies la cache en el
                botón de abajo
              </p>
            </div>
            <!-- <div class="field mt-2">
              <b-switch v-model="me_cache">Datos de tu usuario</b-switch>
            </div>
            <div class="field">
              <b-switch v-model="sofom_cache">Datos de tu sucursal</b-switch>
            </div> -->
          </b-tab-item>
          <b-tab-item label="Hotkeys" icon="keyboard">
            <p class="text-xl font-bold mb-4">Atajos de teclado</p>
            <div class="my-3">
              <span class="font-bold mr-2">buscador:</span>
              <kbd>ctrl</kbd> + <kbd>shift</kbd> +
              <kbd>f</kbd>
            </div>
            <div class="my-3">
              <span class="font-bold mr-2">dashboard:</span>
              <kbd>ctrl</kbd> + <kbd>shift</kbd> +
              <kbd>1</kbd>
            </div>
            <div class="my-3">
              <span class="font-bold mr-2">modulo #2, #3...:</span>
              <kbd>ctrl</kbd> + <kbd>shift</kbd> + <kbd>2</kbd>...
            </div>
            <div class="my-3">
              <span class="font-bold mr-2">Mi perfil:</span>
              <kbd>ctrl</kbd> + <kbd>shift</kbd> + <kbd>.</kbd>
            </div>
          </b-tab-item>
        </b-tabs>
      </section>
      <footer class="modal-card-foot">
        <small>
          Los datos modificados en este apartado solo se verán reflejados en el
          navegador actual
        </small>
      </footer>
    </div>
  </div>
</template>

<script>
import { debounce, getLocalParam, setLocalParam } from "@/vendors/helpers";
import { mapGetters } from "vuex";
import Parametros from "@/components/configuracion/Parametros";

export default {
  name: "ModalConfiguracion",
  data() {
    return {
      sofom_cache: localStorage.getItem("sofom") ? true : false,
      me_cache: localStorage.getItem("me") ? true : false,
      config: null,
      params: [],
      pasteConfig: null,
      reload: 0
    };
  },
  components: {
    Parametros
  },
  methods: {
    /*
      Función que trae la configuración del servidor y la sobreescribe en la local
    */
    serverToLocal() {
      let data = {};
      this.getParametrosPersonalizables.forEach(param => {
        if (param.server_value) {
          data[param.nombre] = param.server_value.data.split("\\").join("");
          if (param.server_value.data[0] == '"') {
            data[param.nombre] = data[param.nombre].slice(1, -1);
          }
        }
      });
      this.$buefy.dialog.confirm({
        title: "Usar configuracíon de la nube",
        message:
          "¿Estas seguro deseas usar esta configuración? no podrás recuperar tu configuración local actual",
        canCancel: ["button"],
        confirmText: "Si, usar configuración",
        cancelText: "Cancelar",
        type: "is-primary",
        onConfirm: () => {
          localStorage.setItem("config_cliente", JSON.stringify(data));
          this.notification(
            "success",
            "Ahora usas la configuración de la nube",
            `Se ha sobreescrito la configuración local anterior`
          );
          this.setParamsLocalServer(
            this.getParametrosPersonalizables,
            this.getParametros
          );
        }
      });
    },
    /*
      Función que pone la configuración local en el servidor
    */
    localToServer() {
      this.$buefy.dialog.confirm({
        title: "Guardar configuracíon local",
        message:
          "¿Estas seguro deseas sobreescribir la configuración de la nube con la local? Este cambio es irrevertible",
        canCancel: ["button"],
        confirmText: "Si, usar configuración",
        cancelText: "Cancelar",
        type: "is-primary",
        onConfirm: () => {
          this.$store
            .dispatch("usuario/setParametros", {
              persona_id: this.getMe.id,
              configuracion: localStorage.getItem("config_cliente")
            })
            .then(data => {
              this.setParamsLocalServer(
                this.getParametrosPersonalizables,
                data
              );
            });
          this.notification(
            "success",
            "Configuración local guardada",
            `Ahora podrás acceder a tu configuración desde otra computadora con tu usuario`
          );
        }
      });
    },
    /*
      Función para copiar la configuración al clipboard para ser mandada a otro usuario de capvital
    */
    copyConfig() {
      const copyText = document.getElementById("copyconfig");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.notification(
        "success",
        "Configuración copiada a portapapeles",
        `¡Tu configuración ha sido copiada correctamente!`
      );
    },
    /*
      Función que recibe la configuración que se pego y la pone tanto en server como local
    */
    saveConfig() {
      if (
        this.pasteConfig != null &&
        this.pasteConfig[0] == "{" &&
        this.pasteConfig[this.pasteConfig.length - 1] == "}"
      ) {
        // this.pasteConfig = this.pasteConfig.split("\\").join("");
        this.$buefy.dialog.confirm({
          title: "Importar configuración",
          message:
            "¿Estas seguro de importar esta configuración? no podrás recuperar tu configuración actual",
          canCancel: ["button"],
          confirmText: "Importar",
          cancelText: "Cancelar",
          type: "is-primary",
          onConfirm: () =>
            this.$store
              .dispatch("usuario/setParametros", {
                persona_id: this.getMe.id,
                configuracion: this.pasteConfig
              })
              .then(data => {
                localStorage.setItem("config_cliente", this.pasteConfig);
                this.setParamsLocalServer(
                  this.getParametrosPersonalizables,
                  data
                );
              })
        });
      } else {
        this.notification(
          "warning",
          "El formato a copiar es erroneo",
          `Intenta copiando la configuración`
        );
      }
    },
    /*
      Función que mapea el catalogo de parametros y agrega tanto la configuración local existente
      como la configuración en el servidor (algo asi como un visualizador en caso de conflictos)
      @catalogo_params (Array) viene de API, el catalogo de parametros disponibles
      @params_usr (Array) viene de API, los parametros que el usuario tiene en el server
    */
    setParamsLocalServer(catalogo_params, params_user) {
      this.params = catalogo_params.map(param => {
        if (getLocalParam(param.nombre)) {
          param.local_value = getLocalParam(param.nombre);
        }
        let param_user = params_user.find(x => x.parametro_id == param.id);
        if (param_user) {
          param.server_value = param_user;
        }
        return param;
      });
      this.reload += 1;
      this.config = localStorage.getItem("config_cliente");
    }
  },
  watch: {
    //Cambia el número de resultados por página de todas las peticiones que cuenten con paginador
    results_page: debounce(function(val) {
      setLocalParam("paginador", val);
      this.notification(
        "success",
        "Configuración actualizada",
        `Se ha cambiado el número de resultados por página`
      );
    }, 500),
    //Guarda en localstorage la repuesta de la API /me
    me_cache: debounce(function(val) {
      if (val) {
        localStorage.setItem("me", JSON.stringify(this.getMe));
        this.notification(
          "success",
          "Datos almacenados",
          `Se han guardado los datos de tu usuario en cache exitosamente`
        );
      } else {
        localStorage.removeItem("me");
        this.notification(
          "success",
          "Datos eliminados de cache",
          `Se han eliminado los datos de cache`
        );
        this.$router.go();
      }
    }, 500),
    //Guarda en localstorage la repuesta de la API /sofom
    sofom_cache: debounce(function(val) {
      if (val) {
        localStorage.setItem("sofom", JSON.stringify(this.getSofom));
        this.notification(
          "success",
          "Datos almacenados",
          `Se han guardado los datos de tu sucursal en cache exitosamente`
        );
      } else {
        localStorage.removeItem("sofom");
        this.notification(
          "success",
          "Datos eliminados de cache",
          `Se han eliminado los datos de cache`
        );
        this.$router.go();
      }
    })
  },
  computed: {
    ...mapGetters(["getMe", "getSofom"]),
    ...mapGetters("usuario", ["getParametros"]),
    ...mapGetters("catalogos", ["getParametrosPersonalizables"])
  },
  mounted() {
    this.config = localStorage.getItem("config_cliente");
    this.$store
      .dispatch("catalogos/getParametrosPersonalizables")
      .then(data => {
        this.$store
          .dispatch("usuario/getParametros", this.getMe.id)
          .then(dataUser => {
            this.setParamsLocalServer(data, dataUser);
          });
      });
  }
};
</script>

<style lang="scss">
.msg-hover {
  transition: all 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
kbd {
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  border-radius: 3px;
  border: 1px solid rgb(204, 204, 204);
  color: rgb(51, 51, 51);
  line-height: 1.4;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  display: inline-block;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), inset 0px 0px 0px 2px #ffffff;
  background-color: rgb(247, 247, 247);
  -moz-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #ffffff inset;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  text-shadow: 0 1px 0 #fff;
}
</style>
