<template>
  <validation-observer
    ref="observer"
    v-slot="{ validate }"
    class="w-full text-left"
  >
    <div class="modal-card">
      <section class="modal-card-head pb-0">
        <p class="font-bold text-2xl">
          Iniciar sesión
        </p>
        <button
          type="button"
          class="delete absolute right-0 mr-5"
          @click="$parent.close()"
        />
      </section>
      <section class="modal-card-body has-background-white text-left">
        <ValidInput
          rules="required|email"
          label="Correo electrónico"
          v-model="form.email"
          label-class="has-text-grey-light font-light"
          type="email"
        />
        <ValidInput
          rules="required"
          label="Contraseña"
          v-model="form.password"
          label-class="has-text-grey-light mt-6 font-light"
          type="password"
        />
      </section>
      <section class="modal-card-foot justify-end">
        <b-button
          type="is-primary"
          @click="validate().then(result => login(result))"
        >
          Iniciar sesión
        </b-button>
      </section>
    </div>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidInput from "@/components/form/ValidInput";
import store from "@/store";

export default {
  name: "ModalLogin",
  components: {
    ValidationObserver,
    ValidInput
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        authorize: "Authorized",
        outside: true
      }
    };
  },
  methods: {
    login(res) {
      if (!res) return;
      store.dispatch("login", this.form).then(() => {
        sessionStorage.setItem("modalOpened", false);
        this.$parent.close();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      });
    }
  }
};
</script>
