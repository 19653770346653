<template>
  <Layout class="main-cols">
    <div class="container is-fluid md:ml-0" v-if="!reporte_seleccionado">
      <h1 class="text-left mb-2">Reportes</h1>
      <p class="text-left">
        Crea un nuevo reporte dando click al botón de abajo
      </p>
      <div class="columns">
        <div class="column text-left mt-4">
          <b-button class="text-left" type="is-primary" outlined>
            Crear nuevo reporte
          </b-button>
        </div>
      </div>
      <p class="tl">O consulta los mas utilizados a continuación</p>
      <div class="columns is-multiline mt-2">
        <div class="column is-4">
          <div
            class="card has-border-radius cursor-pointer"
            @click="loadReporte('LineaCredito')"
          >
            <p class="pt-4 pb-4">Líneas de crédito</p>
          </div>
        </div>
        <div class="column is-4">
          <div class="card has-border-radius">
            <p class="pt-4 pb-4">Archivos</p>
          </div>
        </div>
        <div class="column is-4">
          <div class="card has-border-radius">
            <p class="pt-4 pb-4">Bitácora de accesos</p>
          </div>
        </div>
        <div class="column is-4">
          <div class="card has-border-radius">
            <p class="pt-4 pb-4">Garantías</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container is-fluid md:ml-0" v-if="reporte_seleccionado">
      <b-table
        :data="data"
        :loading="loading"
        paginated
        class="report-table"
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        aria-next-label="Siguiente página"
        aria-previous-label="Página anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      >
        <b-table-column
          v-for="(column, index) in columns"
          :key="index"
          :label="column.label"
          :field="column.field"
          searchable="true"
          v-slot="props"
        >
          {{ setColumValue(props, column) }}
        </b-table-column>
      </b-table>
    </div>
  </Layout>
</template>

<script>
import reportesApi from "@/api/reportes.api";
import moment from "moment";
import { getLocalParam } from "@/vendors/helpers";
import { mapGetters } from "vuex";

export default {
  name: "tablas",
  components: {},
  data() {
    return {
      data: [],
      total: 0,
      reporte_seleccionado: null,
      loading: false,
      sortField: "",
      sortOrder: "",
      defaultSortOrder: "desc",
      page: 1,
      perPage: getLocalParam("paginador") || 20,
      columns: []
    };
  },
  methods: {
    loadAsyncData() {
      this.loading = true;
      const params = [
        "dentro_de=15",
        "por_pagina=" + getLocalParam("paginador") || 20,
        "tipo=vencidos",
        `sort_by=${this.sortField}.${this.sortOrder}`,
        `pagina=${this.page}`
      ].join("&");
      const tipo = this.reporte_seleccionado;
      reportesApi.getEjemplo(
        params,
        tipo,
        result => {
          let data = result.data;
          this.data = [];
          this.total = data.total;
          this.data = data.reporte;
          this.columns = data.columns;
          this.loading = false;
        },
        error => {
          this.data = [];
          this.total = 0;
          this.loading = false;
          throw error;
        }
      );
    },
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    loadReporte(tipo) {
      this.reporte_seleccionado = tipo;
      this.loadAsyncData();
    },
    setColumValue(props, column) {
      if (props.row[column.field]) {
        if (column.type) {
          switch (column.type) {
            case "currency":
              return this.$options.filters.currency(props.row[column.field]);
            case "date":
              return this.$options.filters.moment(props.row[column.field]);
            case "boolean":
              return props.row[column.field] == 1 ? "Sí" : "No";
          }
        }
        return props.row[column.field];
      }
      return "--";
    }
  },
  computed: {
    ...mapGetters("reportes", ["getModelos"]),
    ...mapGetters(["getSofom"])
  },
  filters: {
    moment: function(date) {
      date = this.$moment(date).toDate();
      return moment(date).format("DD-MM-YYYY");
    }
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("reportes/getModelos").then(() => {
      this.loading = false;
    });
  }
};
</script>

<style>
.report-table .table-wrapper {
  overflow: auto !important;
}
</style>
