import { NotificationProgrammatic as Notification } from "buefy";
import PersonasApi from "@/api/personas.api";

/*
Función encargada de validar los permisos de un usuario en determinadas rutas
  - @rutas (array): La lista de rutas que se deseen validar
	- return true si tiene permisos en todas las rutas que se mandaron y false si no tiene permisos en alguna de las rutas
*/
export function validarPermisosRutas(rutas){
  return PersonasApi.getPermisosRutas(
    rutas,
    res => {
      const permisos = [];

      if(res.data.length < 1){
        return true;
      }

      res.data.forEach(element => {
          if(typeof element === 'string'){
            return notification(
              "warning",
              "Operación fallida",
              `${element}`
            );
          }

          permisos.push(element);
      });

      if(permisos.length > 1){
        notification(
          "warning",
          "Operación fallida",
          `No tienes permisos en las rutas ${permisos.join(',')}`
        );
      }

      if(permisos.length == 1){
        notification(
          "warning",
          "Operación fallida",
          `No tienes permisos en la ruta ${permisos[0]}`
        );
      }

      return false;
    },
    error => {
      return error;
    }
  );
}

/*
Función encargada de esperar un tiempo para ejecutar el código, principalmente usada en buscadores
  - @fn (function): La función la cual será ejecutada
  - @delay (integer (milliseconds)): Tiempo de espera para ejecutar la función
	- return función ejecutada con el delay indicado
*/
export function debounce(fn, delay) {
  var timeoutID = null;
  return function() {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function() {
      fn.apply(that, args);
    }, delay);
  };
}

/*
  Función encargada de obtener un parametro de localstorage en base a su nombre
  @nombre (String) nombre del parametro a buscar en localstorage
*/
export function getLocalParam(nombre) {
  if (localStorage.getItem("config_cliente") != null) {
    let config = JSON.parse(localStorage.getItem("config_cliente"));
    if (deepFind(config, nombre) != undefined) {
      return deepFind(config, nombre);
    }
    return null;
  }
  return null;
}

/*
  Función encargada de settear un parametro de localstorage en base a su nombre y valor
  @nombre (String) nombre del parametro en localstorage
  @value ([Array, String, Boolean, ...]) valor del parametro en localstorage
*/
export function setLocalParam(nombre, value) {
  let config = {};
  if (localStorage.getItem("config_cliente") != null) {
    config = JSON.parse(localStorage.getItem("config_cliente"));
  }
  if (
    (Array.isArray(value) && value.length > 0) ||
    Number.isInteger(value) ||
    typeof value === "boolean"
  ) {
    config[nombre] = value;
  } else {
    delete config[nombre];
  }
  localStorage.setItem("config_cliente", JSON.stringify(config));
}

/*
  Función encargada de regresar el id correcto de la disposición a la hora de mostrarlo en cualquier lugar
*/

export function idToShow(disposicion) {
  if (disposicion.folio_credito) return disposicion.folio_credito;
  if (disposicion.pasivo) return disposicion.creditos_autorizados_id;
  return disposicion.id + ' (Simulación)';
}

/*
  Función custom para las notificaciones de capvital, usa las notificaciones de Buefy más el estilo de capvital
  - @type (String): tipo de la notification, puede ser ['success','danger','warning','info']
  - @title (String): titulo de la notificación
  - @message (String): mensaje de la notificación
  - @duration (integer (milliseconds))? la duración de la notificación 
  - return notificación mostrada
*/
export function notification(type, title, message, duration = 4000) {
  let bg = "";
  switch (type) {
    case "warning":
      bg = "bg-gradient-pasiva";
      break;
    case "danger":
      bg = "bg-gradient-danger";
      break;
    case "success":
      bg = "bg-gradient-success";
      break;
    case "info":
      bg = "bg-gradient-primary-secondary";
      break;
    default:
      bg = "has-text-grey-darker";
      break;
  }
  Notification.open({
    type: bg,
    "auto-close": true,
    message: `
    <div class="flex">
      <div class="border-2 border-white rounded-sm"></div>
      <div class="ml-2">
        <p class="md:text-xl sm:text-2xl font-bold has-text-white">${title}</p>
        <p class="font-normal has-text-white">${message}</p>
      </div>
    </div>`,
    duration: duration,
    position: "is-top-right"
  });
}

/*
  Función que obtiene un documento y en base a su extensión regresa el icono del archivo
  - @doc_nombre (String): Nombre del documento con extensión
  return json {color: ..., icon: ....}
*/
export function setDocIcon(doc_nombre) {
  let pos = doc_nombre.match(/\./gi).length;
  let color = "has-text-grey";
  let icon = "file-alt";
  switch (doc_nombre.split(".")[pos]) {
    case "txt":
      color = "has-text-grey";
      icon = "file-alt";
      break;
    case "docx":
    case "doc":
    case "dot":
    case "docm":
    case "dotx":
    case "dotm":
    case "docb":
      color = "has-text-link";
      icon = "file-word";
      break;
    case "csv":
      color = "has-text-warning";
      icon = "file-csv";
      break;
    case "pdf":
      color = "has-text-danger";
      icon = "file-pdf";
      break;
    case "pptx":
    case "ppt":
    case "pot":
    case "pps":
    case "pptm":
    case "potx":
    case "potm":
    case "ppam":
    case "ppsx":
    case "ppsm":
    case "sldx":
    case "sldm":
      color = "has-text-warning";
      icon = "file-powerpoint";
      break;
    case "xls":
    case "xlt":
    case "xlm":
    case "xlsx":
    case "xlsm":
    case "xltx":
    case "xltm":
      color = "has-text-success";
      icon = "file-excel";
      break;
    case "png":
    case "jpeg":
    case "jpg":
    case "gif":
    case "svg":
      color = "has-text-pasivo";
      icon = "file-image";
  }
  return {
    color: color,
    icon: icon
  };
}
/*
  Función que en base al nombre del status de la línea regresa su icono su bg color y su color
  - @status_linea (String): Nombre del status de la línea
  return json {colorIcon: ..., icon: ...., bgIcon: ...}
*/
export function setLineaIcon(status_linea) {
  switch (status_linea) {
    case "Agendado para autorización":
      return {
        icon: "calendar-check",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Análisis concluido":
      return {
        icon: "chart-line",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "En proceso de análisis":
      return {
        icon: "spinner",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Expediente correcto":
      return {
        icon: "clipboard-check",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Autorizada":
      return {
        icon: "check-double",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Autorizada con condiciones":
      return {
        icon: "check-double",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Rechazado":
      return {
        icon: "times",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Sin decisión":
      return {
        icon: "frown",
        bgIcon: "has-background-warning",
        colorIcon: "has-text-warning"
      };
    case "Con contrato firmado":
      return {
        icon: "file-contract",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Contrato en proceso":
      return {
        icon: "file-invoice",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Listo para Firma":
      return {
        icon: "file-signature",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Dispuesto":
      return {
        icon: "flag-checkered",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Listo para disponer":
      return {
        icon: "money-check-alt",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Con problemas":
      return {
        icon: "exclamation",
        bgIcon: "has-background-warning",
        colorIcon: "has-text-warning"
      };
    case "Con expediente integrado":
      return {
        icon: "file-alt",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "En proceso de Integración":
      return {
        icon: "project-diagram",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Listo para analizar":
      return {
        icon: "check",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Prospecto":
      return {
        icon: "bullseye",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Atenta nota":
      return {
        icon: "comment",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Castigado":
      return {
        icon: "times",
        bgIcon: "has-background-danger",
        colorIcon: "has-text-danger"
      };
    case "En proceso de recalificación":
      return {
        icon: "binoculars",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Finalizado":
      return {
        icon: "flag-checkered",
        bgIcon: "has-background-grey-darker",
        colorIcon: "has-text-grey-darker"
      };
    case "Recalificada":
      return {
        icon: "binoculars",
        bgIcon: "has-background-success",
        colorIcon: "has-text-success"
      };
    case "Irrecuperable":
      return {
        icon: "ban",
        bgIcon: "has-background-danger",
        colorIcon: "has-text-danger"
      };
  }
}

export function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

/*
      Función que busca dentro de un objeto, la key
      @obj (Object): objeto a buscar
      @path (String): key a buscar dentro del objeto, puede ser en un objeto nested separando por . ej: pago.acreditado
      - return: valor de la key
    */
export function deepFind(obj, path) {
  var paths = path.split("."),
    current = obj,
    i;

  for (i = 0; i < paths.length; ++i) {
    if (current[paths[i]] == undefined) {
      return undefined;
    } else {
      current = current[paths[i]];
    }
  }
  return current;
}
/*  
    Función encargada de redirigir a una nueva ventana con respecto a la url dada;
*/
export function goTo(url){
  window.open(url, "_blank");
  return;
}

export function base64ToBlob( base64, type = "application/octet-stream" ) {
  const binStr = atob( base64 );
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[ i ] = binStr.charCodeAt( i );
  }
  return new Blob( [ arr ], { type: type } );
}