import axios from "axios";
import store from "@/store";
import { notification } from "@/vendors/helpers";
import router from "@/router";
import { ModalProgrammatic as Modal } from "buefy";
import ModalLogin from "@/components/modals/ModalLogin";
import config from "@/../config";

const http = axios.create();

http.interceptors.request.use(
  config => {
    store.commit("loader/START_LOADING");
    return config;
  },
  error => {
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    if (response.data.error_ultimo_cierre) {
      window.location.replace(config.host + "/" + response.data.url);
    }
    switch (response.config.method) {
      case "post":
        notification(
          "success",
          "Petición a servidor",
          "¡Operación realizada con exito!"
        );
        break;
      case "patch":
        notification(
          "success",
          "Petición a servidor",
          "¡Operación actualizada con exito!"
        );
        break;
      case "delete":
        notification(
          "success",
          "Petición a servidor",
          "¡Operación eliminada con exito!"
        );
        break;
    }
    store.commit("loader/FINISH_LOADING");
    return response;
  },
  error => {
    store.commit("loader/SET_CERO");
    let errores = "";
    let error_to_show = "";
    if (error.response.data.message) {
      error_to_show = error.response.data.message;
    } else if (typeof error.response.data == "object") {
      Object.entries(error.response.data).forEach(([key, value]) => {
        let valor = key.replace(/_/g, " de ");
        valor = valor.replace(/\./g, " en ");
        valor += value.includes("required")
          ? ": el valor es requerido"
          : ": el tipo del valor es incorrecto";
        errores += "<li class='text-sm'>" + valor + "</li>";
      });
      error_to_show =
        "<p class='font-bold error-title-notif'>Ups... ha ocurrido un detalle al procesar la información</p><ul class='list-disc'>" +
        errores +
        "</ul>";
    } else {
      error_to_show =
        "Ups... ha ocurrido un error al procesar la petición, porfavor contacta a soporte";
    }

    switch (error.response.status) {
      case 500:
        // se deshabilita el envio del correo
        // store.dispatch("helpers/sendError", {
        //   metodo: error.response.config.method,
        //   ruta_api: error.response.config.url,
        //   ruta: router.currentRoute.fullPath,
        //   data: error.response.config.data || "N/A",
        //   store: store.state
        // });
        // store.commit("loader/FINISH_LOADING");

        // router.push({ name: "error_500", params: error.response });
        break;
      case 404:
        router.push({ name: "error_404", params: error.response });
        break;
      case 403:
        router.push({ name: "error_403", params: error.response });
        break;
      case 400:
        router.push({ name: "error_403", params: error.response });
        break;
      case 401:
        if (error.response.data.message == "Tiempo excedido") {
          if (
            !sessionStorage.getItem("modalOpened") ||
            sessionStorage.getItem("modalOpened") == "false"
          ) {
            Modal.open({
              parent: this,
              component: ModalLogin,
              hasModalCard: true,
              trapFocus: true
            });
            notification(
              "warning",
              "Tu sesión ha finalizado",
              "Vuelve a iniciar sesión"
            );
            sessionStorage.setItem("modalOpened", true);
          }
        }
        if (error.response.data.message == "Authorization required") {
          notification(
            "warning",
            "No tienes autorización",
            "Ruta: " + error.response.config.url,
            100000
          );
        }
        break;
    }
    if (error_to_show != "Authorization required") {
      notification("danger", error_to_show, "Contacta a tu administrador");
    }
    store.commit("loader/FINISH_LOADING");
    return Promise.reject(error);
  }
);

export default http;
