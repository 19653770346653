import config from "@/../config";
import http from "@/api/http";

export default {
  addCuentaBancaria(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/sofom/cuentas_bancarias`, data)
      .then(onSuccess)
      .catch(onError);
  },
  getCuentasBancarias(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/sofom/cuentas_bancarias`)
      .then(onSuccess)
      .catch(onError);
  },
  editCuentaBancaria(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/sofom/cuentas_bancarias/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  deleteCuentaBancaria(cuenta_id, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/sofom/cuentas_bancarias/${cuenta_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getPersonasPermisosSubmodulo(submodulo_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/submodulo/${submodulo_id}/personas`
      )
      .then(onSuccess)
      .catch(onError);
  },
  changePermisosPersonas(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/submodulo/${data.submodulo_id}/personas`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  updateSofom(data, onSuccess, onError) {
    return http
      .patch(`${config.host}/${config.version}/sofom`, data)
      .then(onSuccess)
      .catch(onError);
  }
};
