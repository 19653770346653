<template>
  <div class="loading-modal animated fadeIn has-background-grey-darker-02">
    <div
      class="card has-background-white text-center has-border-radius shadow-md"
    >
      <b-image src="/images/loading.gif" class="p-4" />
      <h3 class="font-bold has-text-grey-darker">{{ description }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    description: String
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
.loading-modal {
  width: 100vw;
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  top: 0;
  z-index: 1004;
  > div {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20em;
    img {
      margin: 0 auto;
      width: 70%;
    }
    h3 {
      color: $grey-darker;
      font-size: 1.3rem;
      padding-bottom: 1.5rem;
      &:after {
        content: ".";
        animation: dots 1.5s steps(5, end) infinite;
      }
    }
  }
}
</style>
