import config from "@/../config";
import http from "@/api/http";

export default {
  getPago(pago_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/pago/${pago_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  deletePago(pago_id, onSucces, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/pagos/${pago_id}`
      )
      .then(onSucces)
      .catch(onError);
  },
  getComentarios(pago_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/pago/${pago_id}/comment`)
      .then(onSuccess)
      .catch(onError);
  },
  setComentario(data, onSuccess, onError) {
    return http
      .patch(`${config.host}/${config.version}/pago/${data.id}/comment`, data)
      .then(onSuccess)
      .catch(onError);
  },
  getDocumentos(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/pago/${id}/files`)
      .then(onSuccess)
      .catch(onError);
  },
  ligarArchivos(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/pago/${data.id}/ligar_docs`, data)
      .then(onSuccess)
      .catch(onError);
  },
  desligarArchivos(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/pago/${data.id}/desligar_docs/${data.file_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getTicket(pago_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/pago/${pago_id}/ticket`)
      .then(onSuccess)
      .catch(onError);
  },
  getDashboard(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/reportes/pagos_dashboard/${data.pasivo}/${data.fecha}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  getDisposicion(disposicion_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/credito/caja/${disposicion_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getCuentaCP(cuenta_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/cuentas/${cuenta_id}`)
      .then(onSuccess)
      .catch(onError);
  },
  simular(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/credito/${data.credito_id}/simular_pago`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  pagarDisposicion(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/credito/${data.doc_id}/pago`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  pagarDisposicionEspecial(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/credito/${data.doc_id}/pago_especial`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  pagarCuentaCP(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/cuentas/pago/${data.doc_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getGarantiaPasivo(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/garantias/${id}/saldo_pasivo`)
      .then(onSuccess)
      .catch(onError);
  },
  getGarantiaActivo(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/garantias/${id}/saldo_activo`)
      .then(onSuccess)
      .catch(onError);
  },
  pagarGarantiaPasivo(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/garantias/${data.doc_id}/pago_pasiva`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  cobrarGarantiaActivo(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/garantias/${data.doc_id}/pago_activa`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  }
};
