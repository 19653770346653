import config from "@/../config";
import http from "@/api/http";

export default {
  getPreview(data, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/object_preview/${data.tipo}/${data.id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  sendError(data, onSuccess, onError) {
    try {
      return http
      .post(`${config.host}/${config.version}/error500`, data)
      .then(onSuccess)
      .catch(onError);
    } catch (error) {
      console.error(error);
    }
   
  }
};
