<template>
  <b-dropdown position="is-bottom-left" aria-role="menu" expanded class="pl4">
    <a
      class="flex justify-around navbar-item has-border-radius items-center dropdown-user"
      slot="trigger"
      role="button"
    >
      <figure class="image">
        <img
          class="is-rounded w-auto"
          :src="'https://www.gravatar.com/avatar/' + me.avatar + '?d=identicon'"
        />
      </figure>
      <span class="has-text-grey-darker">{{ sofom.nombre_comercial }}</span>
      <fa-icon icon="caret-down" />
    </a>
    <b-dropdown-item custom aria-role="menuitem">
      <div class="img-container w-30 center is-hidden-desktop">
        <img :src="sofom.logo ? sofom.logo.path : '/images/logo-white.png'" alt="" class="w-40 m-auto" />
      </div>
      <b>{{ me.alias }}</b>
      <p class="is-hidden-desktop">
        <small>
          Sistema al día:
          <b>{{ sofom.fecha_cierre | moment("DD-MM-YYYY") }}</b>
        </small>&nbsp;
        <small>Zona horaria:<b>{{ sofom.timezone }}</b></small>&nbsp;
        <small>Sucursal:<b>{{ me.sucursal_activa.nombre }}</b></small>
      </p>
    </b-dropdown-item>
    <hr class="dropdown-divider" />
    <b-dropdown-item
      aria-role="menuitem"
      class="is-hidden-tablet"
      @click="buzonAnonimo()"
    >
      <fa-icon icon="user-secret" size="lg" class="mr2" />
      Buzon Anonimo
    </b-dropdown-item>
    <b-dropdown-item
      aria-role="menuitem"
      class="is-hidden-tablet"
      @click="ayudaModal()"
    >
      <fa-icon icon="question" size="lg" class="mr2" />
      Ayuda
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <a
        @click="
          goTo(
            config.client + '/#/administracion/personas/ver/perfiles/' + me.id
          )
        "
        id="myProfile"
      >
        <fa-icon icon="user" size="lg" class="mr2" />
        Mi perfil
      </a>
    </b-dropdown-item>
    <b-dropdown-item @click="cambiarSucursal" aria-role="menuitem">
      <fa-icon icon="building" size="lg" class="mr2" />
      Seleccionar sucursal
    </b-dropdown-item>
    <b-dropdown-item has-link aria-role="menuitem">
      <a @click="goTo(config.host + '/' + config.version + '/forgotten')">
        <fa-icon icon="key" size="lg" class="mr2" />
        Cambiar contraseña
      </a>
    </b-dropdown-item>
    <b-dropdown-item aria-role="menuitem" @click="configModal">
      <fa-icon icon="cog" size="lg" class="mr2" />
      Ajustes del sistema
    </b-dropdown-item>
    <b-dropdown-item aria-role="menuitem" @click="toggleDarkmode()">
      <div v-if="darkmode">
        <fa-icon icon="sun" size="lg" class="mr2" />
        Modo claro
      </div>
      <div v-else>
        <fa-icon icon="moon" size="lg" class="mr2" />
        Modo oscuro
      </div>
    </b-dropdown-item>
    <hr class="dropdown-divider" aria-role="menuitem" />
    <b-dropdown-item aria-role="menuitem" @click="logout">
      <div class="has-text-danger">
        <fa-icon icon="sign-out-alt" size="lg" class="mr2" />
        Cerrar sesión
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import ModalConfiguracion from "@/components/layout/ModalConfiguracion";
import CambiarSucursal from "@/components/layout/CambiarSucursal";
import { goTo } from "@/vendors/helpers";

export default {
  name: "dropdownUser",
  props: {
    config: Object,
    sofom: Object,
    me: Object
  },
  data() {
    return {
      darkmode: false
    };
  },
  watch: {
    darkmode: function() {
      let htmlElement = document.documentElement;
      if (this.darkmode) {
        localStorage.setItem("theme", "dark");
        htmlElement.setAttribute("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
        htmlElement.setAttribute("theme", "light");
      }
    }
  },
  methods: {
    /*
      Abre el modal "ModalConfiguracion"
    */
    configModal() {
      this.$buefy.modal.open({
        parent: this,
        component: ModalConfiguracion,
        hasModalCard: true,
        trapFocus: true
      });
    },
    /*
      Abre el modal "CambiarSucursal"
    */
    cambiarSucursal() {
      this.$buefy.modal.open({
        parent: this,
        component: CambiarSucursal,
        hasModalCard: true,
        trapFocus: true
      });
    },
    /*
      Función encargada de realizar el logout de capvital
    */
    logout() {
      this.$store.dispatch("logOut", this.me).then(() => {
        localStorage.removeItem("accessToken");
        const redirectUri = encodeURIComponent(this.config.client);
        const scope = this.config.scope;
        let url =
          this.config.host + this.config.oauth +
          "/oauth/authorize?response_type=token&client_id=" +
          this.config.client_id +
          "&redirect_uri=" +
          redirectUri +
          "/&scope=" +
          scope;
        window.location.replace(url);
      });
    },
    /*
      Activa/desactiva darkmode
    */
    toggleDarkmode() {
      this.darkmode = !this.darkmode;
    },
    goTo(url) {
      goTo(url);
    }
  },
  mounted() {
    if (localStorage.getItem("theme") == "dark") {
      this.darkmode = true;
    } else {
      this.darkmode = false;
    }
  }
};
</script>

<style lang="scss">
.dropdown-user {
  &:hover {
    span {
      color: var(--white) !important;
    }
  }
}
</style>
