<template>
  <div
    class="rounded-full text-center"
    :class="[
      {
        'p-2 w-10': size == 'small',
        'py-4 w-56px': size != 'small',
        'flex items-center justify-center': tipo == 1,
        direction
      },
      color
    ]"
  >
    <b-icon v-if="tipo == 1" icon="building" />
    <span v-else>
      {{ initials }}
    </span>
  </div>
</template>

<script>
export default {
  name: "IconInitials",
  props: {
    nombre: String,
    tipo: Number,
    size: String,
    direction: {
      type: String,
      default: "m-auto"
    }
  },
  computed: {
    initials() {
      if (this.nombre != null) {
        let initials = this.nombre.split(" ");
        if (initials.length > 1) {
          initials = initials[0][0] + initials[1][0];
        } else {
          initials = this.nombre.split("");
          initials = initials[0] + initials[1];
        }
        return initials.toString().toUpperCase();
      }
      return "";
    },
    color() {
      switch (this.initials[0]) {
        case "A":
        case "H":
        case "O":
        case "V":
          return "has-background-primary-04 has-text-primary";
        case "B":
        case "I":
        case "P":
        case "W":
          return "has-background-pasivo-04 has-text-pasivo";
        case "C":
        case "J":
        case "Q":
        case "X":
          return "has-background-success-light has-text-success";
        case "D":
        case "K":
        case "R":
        case "Y":
          return "has-background-warning-light has-text-warning";
        case "E":
        case "L":
        case "S":
        case "Z":
          return "has-background-danger-light has-text-danger";
        case "F":
        case "M":
        case "T":
        case "Ñ":
          return "has-background-info-light has-text-info";
        case "G":
        case "N":
        case "U":
          return "has-background-secondary-light has-text-secondary";
      }
      return "";
    }
  }
};
</script>
