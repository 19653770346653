<template>
  <div
    class="columns card-search mt-0 is-mobile"
    @click="setActive(tipo, item)"
    :class="{
      active: active != null && active.tipo == tipo && active.item.id == item.id
    }"
  >
    <div class="column is-2 items-center justify-center hidden md:flex">
      <icon-initials
        class="inline-block m-0 w-fu"
        v-if="tipo == 'persona'"
        :nombre="item.nombre_completo"
        size="small"
      />
      <b-icon
        v-else
        :icon="getDetail(tipo, item).icon"
        :class="getDetail(tipo, item).color_icon"
        class="has-text-primary p-5 rounded-full"
      />
    </div>
    <div class="column flex flex-col justify-center text-left ">
      <p class="text-xl">{{ getDetail(tipo, item).nombre }}</p>
      <p class="has-text-grey-light" v-if="!hideSub">
        {{ getDetail(tipo, item).subtitulo }}
      </p>
    </div>
    <div class="column is-3 flex items-center justify-end">
      <p
        class="text-right inline-block pr-4 font-medium"
        :class="getDetail(tipo, item).color_status"
      >
        {{ getDetail(tipo, item).status }}
      </p>
      <b-icon :icon="icon" />
    </div>
  </div>
</template>

<script>
import IconInitials from "@/components/generals/IconInitials";

export default {
  name: "BuscadorCard",
  components: {
    IconInitials
  },
  props: {
    tipo: String,
    item: Object,
    icon: {
      type: String,
      default: "chevron-right"
    },
    hideSub: {
      type: Boolean,
      default: false
    },
    active: Object
  },
  methods: {
    /*
      Selecciona el objeto como activo para mostrar su preview
      @tipo (String): tipo de objeto
      @item (Object): el objeto en si
    */
    setActive(tipo, item) {
      this.$emit("active", {
        tipo,
        item
      });
    },
    /* 
      Obtiene la descripción a mostrar en base al objeto que sea
      @tipo (String): tipo de objeto
      @item (Object): el objeto en si
    */
    getDetail(tipo, item) {
      let icon = "";
      switch (tipo) {
        case "linea":
          return {
            icon: "hand-holding-usd",
            nombre: "Línea #" + item.id,
            subtitulo: `Cliente #${item.acreditado.id}: ${item.acreditado.alias}`,
            status: item.status_linea.nombre,
            color_status:
              item.status_linea.id == 2 ? "has-text-success" : "has-text-grey"
          };
        case "garantia":
          if (item.tipo_garantia.parent_tipos_garantia_id == 3) {
            icon = "university";
          }
          icon = "dollar-sign";
          return {
            icon: icon,
            nombre: "Garantía #" + item.id,
            subtitulo: item.acreditado
              ? `Cliente #${item.acreditado.id}: ${item.acreditado.alias}`
              : "N/A"
          };
        case "cuentaCP":
          return {
            icon: "money-check-alt",
            nombre: "Cuenta #" + item.id,
            subtitulo: item.acreditado
              ? `Cliente #${item.acreditado.id}: ${item.acreditado.alias}`
              : "N/A",
            status: item.cobro ? "Cuenta por cobrar" : "Cuenta por pagar"
          };
        case "credito":
          return {
            icon: "wallet",
            nombre: "Disposición #" + item.idToShow,
            subtitulo: item.acreditado
              ? `Cliente #${item.acreditado.id}: ${item.acreditado.alias}`
              : "Cliente No definido",
            color_icon: item.pasivo
              ? "has-background-pasivo-04 has-text-pasivo"
              : "has-background-primary-04 has-text-primary",
            status: item.status.nombre,
            color_status:
              item.status_id == 8 || item.status_id == 17
                ? "has-text-success"
                : "has-text-grey"
          };
        case "pago":
          return {
            icon: "credit-card",
            nombre: "Pago #" + item.id,
            subtitulo: `Cliente #${item.persona.id}: ${
              item.persona.nombre_completo.split("|")[0]
            }`
          };
        case "persona":
          return {
            nombre: item.nombre_completo.split("|")[0],
            subtitulo: item.tipo_persona
              ? item.tipo_persona.nombre
              : "Tipo de persona desconocido"
          };
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.card-search {
  transition: all 0.3s ease-in-out;
  border-radius: $radius;
  cursor: pointer;
  &:hover {
    background-color: rgba($primary, 0.08);
  }
  &.active {
    background-color: rgba($primary, 0.16);
  }
}
</style>
