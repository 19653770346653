import GarantiasApi from "@/api/garantias.api";

const state = {
  garantia: null,
  personas: null,
  garantias: null,
  garantiaParaArrendar: null
};
const getters = {
  getGarantia: state => state.garantia,
  getPersonas: state => state.personas,
  getGarantias: state => state.garantias,
  getGarantiaParaArrendar: state => state.garantiaParaArrendar,
};
const mutations = {
  SET_GARANTIA(state, garantia) {
    state.garantia = garantia;
  },
  SET_PERSONAS(state, personas) {
    state.personas = personas;
  },
  SET_GARANTIAS(state, garantias) {
    state.garantias = garantias;
  },
  SET_GARANTIA_ARRENDAR(state, garantia) {
    state.garantiaParaArrendar = garantia;
  }
};
const actions = {
  getGarantiaById({ commit }, garantia_id) {
    return GarantiasApi.getGarantiaById(
      garantia_id,
      res => {
        commit("SET_GARANTIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setGarantiaParaArrendar({ commit }, garantia) {
    commit("SET_GARANTIA_ARRENDAR", garantia);
  },
  reclamar({ commit }, data) {
    return GarantiasApi.reclamar(
      data,
      res => {
        commit("SET_GARANTIA", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getPersonas({ commit }, garantia_id) {
    return GarantiasApi.getPersonas(
      garantia_id,
      res => {
        commit("SET_PERSONAS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  buscar({ commit }, params) {
    return GarantiasApi.buscar(
      params,
      res => {
        commit("SET_GARANTIAS", res.data.garantias);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
