<template>
  <ValidationProvider :rules="rules == 'none' ? '' : rules">
    <b-field>
      <b-upload
        v-model="files"
        multiple
        drag-drop
        :class="{ expanded: expanded }"
        @input="submitFiles()"
      >
        <section class="section py-4 has-background-grey-lighter">
          <div class="content has-text-centered">
            <b-image src="/images/files.png" class="m-auto w-1/4" />
            <p class="text-xl font-bold mt-2 mb-0">
              Arrastra un archivo y suéltalo aquí o
              <span class="has-text-link underline">búscalo</span> en tu
              ordenador
            </p>
          </div>
        </section>
      </b-upload>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
export default {
  name: "ValidUpload",
  data() {
    return {
      files: []
    };
  },
  components: {
    ValidationProvider
  },
  props: {
    expanded: Boolean,
    rules: String
  },
  methods: {
    submitFiles() {
      this.$emit("input", this.files);
      this.files = [];
    }
  }
};
</script>

<style lang="scss">
.upload.expanded,
.upload.expanded > div {
  width: 100%;
}
.upload .upload-draggable {
  border: 4px dashed var(--white-ter);
}
</style>
