<template>
  <div v-if="getMe && getSofom">
    <main id="panel">
      <div class="columns is-mobile mx-0">
        <div id="sidebarContainer" class="pb-0 z-10">
          <Sidebar @checkOpen="checkOpen" />
        </div>
        <div
          class="min-h-102vh relative md:pl-10"
          id="main"
          v-if="getSofom && !getSidebarOpenMobile"
        >
          <TopNavbar :menustatus="menu_open" :sofom="getSofom" :me="getMe" />
          <Breadcrumb class="mt-20 pt-6" />
          <section
            class="relative columns main-cols-transition"
            @click="closeSidebar"
          >
            <div class="column is-12">
              <slot v-if="getBusqueda == null" />
              <busqueda v-else />
            </div>
          </section>
        </div>
        <div
          class="sticky-col"
          v-if="getPreviewKeys.tipo != null && getPreviewKeys.id != null"
        >
          <portal-target name="previewContainer" />
        </div>
        <portal-target name="modalContainer" multiple />
        <b-modal
          :active.sync="files"
          :canCancel="true"
          class="capvital-files-modal"
          v-if="files"
        >
          <capvital-files />
        </b-modal>
      </div>
    </main>
  </div>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar";
import TopNavbar from "@/components/layout/TopNavbar";
import Breadcrumb from "@/components/layout/Breadcrumb";
import Busqueda from "@/components/generals/Busqueda";
import { mapGetters } from "vuex";

export default {
  name: "Dashboardv1",
  components: {
    Sidebar,
    TopNavbar,
    Breadcrumb,
    Busqueda,
  },
  data() {
    return {
      description: "Cargando Capvital, Espera un momento porfavor.",
      modulo: null,
      menu_open: false
    };
  },
  watch: {
    $route: {
      handler: function() {
        if (this.getPreviewKeys) {
          this.$store.dispatch("helpers/closePreview");
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeSidebar() {
      if (this.getSidebarOpen || this.getSidebarOpenMobile) {
        this.$store.dispatch("setSidebarOpen", false);
      }
    },
    // Comprueba si el modulo no tiene submodulos y va directamente a su ruta en caso de no tener.
    checkOpen: function(modulo) {
      if (modulo.path) {
        window.location = process.env.VUE_APP_CLIENT + modulo.path;
      }
    }
  },
  computed: {
    ...mapGetters([
      "getSofom",
      "getMe",
      "getAyudaActual",
      "getAyudas",
      "getBusqueda",
      "getSidebarOpen",
      "getSidebarOpenMobile"
    ]),
    ...mapGetters("files", ["getStatus"]),
    ...mapGetters("helpers", ["getPreviewKeys"]),
    files: {
      // getter
      get: function() {
        return this.getStatus;
      },
      set: function(val) {
        this.$store.dispatch("files/toggleCapvitalFiles", val);
      }
    }
  },
  mounted() {
    if (this.getAyudas == null) {
      this.$store.dispatch("getAyudas").then(data => {
        let ayuda_actual = data.find(ayuda => this.$route.name == ayuda.uri);
        let ayuda_global = data.find(ayuda => ayuda.uri == "");
        if (ayuda_global) {
          this.$store.dispatch("setAyudaActual", ayuda_global);
        }
        if (ayuda_actual) {
          this.$store.dispatch("setAyudaActual", ayuda_actual);
        }
      });
    } else {
      let ayuda_actual = this.getAyudas.find(
        ayuda => this.$route.name == ayuda.uri
      );
      let ayuda_global = this.getAyudas.find(ayuda => ayuda.uri == "");
      if (ayuda_actual) {
        this.$store.dispatch("setAyudaActual", ayuda_actual);
      }
      if (ayuda_global) {
        this.$store.dispatch("setAyudaActual", ayuda_global);
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
@import "~bulma/sass/utilities/_all";
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "@/scss/_slideout.scss";
@import "@/scss/_notifications.scss";
@import "@/scss/_helpers.scss";
#panel {
  background-image: linear-gradient(
    to bottom,
    var(--sidebar-primary),
    var(--sidebar-secondary)
  ) !important;
}
#main {
  // overflow: hidden;
  background-color: var(--white);
  border-top-left-radius: 52px;
  border-bottom-left-radius: 30px;
  width: 94% !important;
  flex: none;
  padding: 0.75rem;
  @media screen and (max-width: 768px) {
    width: 100% !important;
    padding: 0 0.75rem 0.75rem;
  }
}
.capvital-files-modal {
  > .modal-content,
  > .animation-content {
    max-width: 1100px !important;
    border-radius: $radius;
    position: relative;
  }
}
.b-checkbox.checkbox input[type="checkbox"] + .check {
  border-radius: 4px;
}
.help.is-danger {
  color: $danger !important;
}
.error-title-notif {
  border-bottom: 2px solid var(--white);
}
.min-h-102vh {
  min-height: 102vh;
}
.sticky-col > div {
  position: fixed;
  top: 7rem;
  right: 1rem;
  min-width: 400px;
  max-width: 400px;
  z-index: 100000;
}
.main-cols-transition > div {
  transition: all 0.5s ease-in-out;
}
@keyframes dots {
  0%,
  20% {
    color: rgba(var(--grey-darker), 0);
    text-shadow: 0.25em 0 0 rgba(var(--grey-darker), 0),
      0.5em 0 0 rgba(var(--grey-darker), 0);
  }
  40% {
    color: var(--grey-darker);
    text-shadow: 0.25em 0 0 rgba(var(--grey-darker), 0),
      0.5em 0 0 rgba(var(--grey-darker), 0);
  }
  60% {
    text-shadow: 0.25em 0 0 var(--grey-darker),
      0.5em 0 0 rgba(var(--grey-darker), 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 var(--grey-darker), 0.5em 0 0 var(--grey-darker);
  }
}
</style>
