import SupervisionApi from "@/api/supervision.api";

const initialState = {
  detalle: null,
  requisito_status: null
};
const state = initialState;

const getters = {
  getDetalle: state => state.detalle,
  getRequisitoStatus: state => state.requisito_status
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState);
  },
  SET_DETALLE(state, detalle) {
    state.detalle = detalle;
  },
  SET_REQUISITO_STATUS(state, status) {
    state.requisito_status = status;
  },
  SET_FECHA_REAGENDADA(state, fecha) {
    if (state.detalle.hasOwnProperty("fecha_reagendada")) {
      state.detalle.fecha_reagendada = fecha;
    }
  }
};
const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  getDetalle({ commit }, id) {
    return SupervisionApi.getDetalle(
      id,
      res => {
        commit("SET_DETALLE", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getRequisitoStatus({ commit }) {
    return SupervisionApi.getRequisitoStatus(
      res => {
        commit("SET_REQUISITO_STATUS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  changeRequisitoStatus({ commit }, data) {
    return SupervisionApi.changeRequisitoStatus(
      data,
      res => {
        commit("SET_DETALLE", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  ligarArchivos({ commit }, form) {
    return SupervisionApi.ligarArchivos(
      form,
      res => {
        commit("SET_DETALLE", res.data);
        return res.data;
      },
      () => false
    );
  },
  desligarArchivos({ commit }, data) {
    return SupervisionApi.desligarArchivos(
      data,
      res => {
        commit("SET_DETALLE", res.data);
        return res.data;
      },
      () => false
    );
  },
  agregarComentarioRequisito({ commit }, data) {
    return SupervisionApi.agregarComentario(
      data,
      res => {
        commit("SET_DETALLE", res.data);
        return res.data;
      },
      () => false
    );
  },
  reagendar({ commit }, data) {
    return SupervisionApi.reagendar(
      data,
      res => {
        commit("SET_FECHA_REAGENDADA", res.data);
        let info = {
          supervision_id: data.id,
          fecha_reagendada: res.data
        };
        commit("disposicion/SET_SUPERVISION_REAGENDADA", info, { root: true });
        return res.data;
      },
      () => false
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
