/*
  Dentro de este documento se encuentran las validaciones de vee-validate con mensajes en español
  para agregar uno nuevo importarlo desde vee-validate/dist/rules donde esta una lista de los disponibles
  o se puede crear el propio, para usarlo es dentro de la directiva 'rules' en cualquier input
*/
import {
  required,
  confirmed,
  email,
  min_value,
  max_value,
  numeric
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Este campo es requerido"
});

extend("numeric", {
  ...numeric,
  message: "Este campo solo acepta números"
});

extend("length", (value, length) => {
  if (value.length != parseInt(length[0])) {
    return `Este campo debe contener ${length[0]} caracteres`;
  }
  return true;
});

extend("min_value", {
  ...min_value,
  message: "Este campo debe tener un valor minímo"
});
extend("max_value", {
  ...max_value,
  message: "Este campo no puede ser mayor de lo especificado"
});

extend("currency", value => {
  if (value[0] == "-") {
    return "Este campo debe ser una cantidad de dinero positiva";
  }
  if (value == "Infinity") {
    return "Este campo no puede ser infinito";
  }
  return true;
});

extend("notcero", value => {
  if (value == "$0.00" || value == 0 || value == 0.0) {
    return "Este campo debe ser mayor a 0";
  }
  return true;
});

extend('repeated_value', (value, values) => {
  let count = 0;
  let isRepeated = false;

  for (let i = 0; i < values.length; i++) {
    if (values[i] == '' + value) count++;

    if (count > 1) {
      isRepeated = true;
      break;
    };
  }

  if (isRepeated) {
    return 'Se ingresó un valor repetido'
  }

  return true;
});

extend("email", {
  ...email,
  message: "Debes escribir un correo valido"
});

extend("confirmed", {
  ...confirmed,
  message: "La confirmación no coincide"
});
