import config from "@/../config";
import http from "@/api/http";

export default {
  getDetalle(id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/supervision/${id}`)
      .then(onSuccess)
      .catch(onError);
  },
  getRequisitoStatus(onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/supervision/requisitos/status`)
      .then(onSuccess)
      .catch(onError);
  },
  changeRequisitoStatus(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/supervision/requisitos/status`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  ligarArchivos(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/supervision/ligar_docs/${data.id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  desligarArchivos(data, onSuccess, onError) {
    return http
      .delete(
        `${config.host}/${config.version}/supervision/desligar_docs/${data.id}/${data.file_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  agregarComentario(data, onSuccess, onError) {
    return http
      .post(
        `${config.host}/${config.version}/supervision/agregar_comentario/${data.requisito_id}`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  reagendar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/supervision/${data.id}/reagendar`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  }
};
