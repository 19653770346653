<template>
  <div class="overflow-hidden h-full">
    <div class="columns h-full" :class="{ 'min-h-15rem': !small }">
      <div
        class="column is-3 relative"
        v-if="!vertical && imagen"
        :class="{ 'is-5 flex': type == 'svg' }"
      >
        <b-image
          :src="imagen"
          :class="
            ({ 'absolute left-0 w-4/5': type != 'svg', imgclass }, imgclass)
          "
          class="m-auto hidden md:block"
        />
      </div>
      <div
        class="column flex justify-center flex-col items-center"
        :class="
          ({
            'items-center': vertical,
            'text-left': !small,
            'text-center': small
          },
          textClass)
        "
      >
        <b-image
          :src="imagen"
          class="hidden md:block"
          v-if="vertical"
          :class="imgclass"
        />
        <p
          class="font-bold capitalize has-text-grey-darker"
          :class="({ 'text-lg': small, 'text-2xl': !small }, tituloClass)"
        >
          {{ titulo }}
        </p>
        <p :class="subtituloClass">{{ subtitulo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    imagen: String,
    type: String,
    titulo: String,
    subtitulo: String,
    vertical: {
      type: Boolean,
      default: false
    },
    imgclass: String,
    small: {
      type: Boolean,
      default: false
    },
    tituloClass: String,
    subtituloClass: String,
    textClass: String
  }
};
</script>
