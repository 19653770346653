/*
  La tiendona de Capvital
  mas info: https://vuex.vuejs.org/
  - para agregar un nuevo modulo se importa y se pone en modules
  - basicamente una acción hace un commit y el commit actualiza el valor del state
  - en el state nunca se cambia su valor directamente, solo por medio de commits
  - El 99% de las llamadas a la API pasa por una action primero y su resultado es guardado en un state
*/
import Vue from "vue";
import Vuex from "vuex";
import sessionApi from "@/api/session.api";
import catalogos from "@/store/modules/catalogos.store";
import garantias from "@/store/modules/garantias.store";
import disposicion from "@/store/modules/disposicion.store";
import lineascredito from "@/store/modules/lineascredito.store";
import reportes from "@/store/modules/reportes.store";
import files from "@/store/modules/files.store";
import pagos from "@/store/modules/pagos.store";
import loader from "@/store/modules/loader.store";
import helpers from "@/store/modules/helpers.store";
import empresa from "@/store/modules/empresa.store";
import personas from "@/store/modules/personas.store";
import supervision from "@/store/modules/supervision.store";
import usuario from "@/store/modules/usuario.store";
import grupoCredito from "@/store/modules/grupoCredito.store";
import moment from "moment";
import http from "@/api/http";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accessToken: localStorage.getItem("accessToken") || "",
    me: null,
    sofom: null,
    sys_admins: null,
    isAuthenticathed: false,
    currency: "currency",
    ayudas: null,
    ayuda_actual: null,
    resultados_busqueda: null,
    busqueda_nombre: null,
    sidebar_open: false,
    sidebar_open_mobile: false
  },
  getters: {
    getMe: state => state.me,
    getSofom: state => state.sofom,
    getSysAdmins: state => state.sys_admins,
    getIsAuthenticated: state => state.isAuthenticathed,
    getFechaCierre: state =>
      state.sofom ? moment(state.sofom.fecha_cierre).toDate() : null,
    getAyudas: state => state.ayudas,
    getAyudaActual: state => state.ayuda_actual,
    getBusqueda: state => state.resultados_busqueda,
    getBusquedaNombre: state => state.busqueda_nombre,
    getSidebarOpen: state => state.sidebar_open,
    getSidebarOpenMobile: state => state.sidebar_open_mobile
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.accessToken = token;
    },
    SET_USER(state, user) {
      state.me = user;
    },
    SET_SOFOM(state, sofom) {
      Vue.prototype.$fecha_cierre = Vue.moment(sofom.fecha_cierre).toDate();
      state.sofom = sofom;
    },
    SET_SYS_ADMINS(state, sys_admins) {
      state.sys_admins = sys_admins;
    },
    SET_IS_AUTHENTICATED(state) {
      state.isAuthenticathed = true;
    },
    SET_HARRY_POTTER(state, value) {
      state.currency = value;
    },
    SET_AYUDAS(state, ayudas) {
      state.ayudas = ayudas;
    },
    SET_AYUDA_ACTUAL(state, ayuda) {
      state.ayuda_actual = ayuda;
    },
    SET_BUSQUEDA(state, resultados) {
      state.resultados_busqueda = resultados;
    },
    SET_BUSQUEDA_NOMBRE(state, nombre) {
      state.busqueda_nombre = nombre;
    },
    SET_SIDEBAR_OPEN(state, status) {
      state.sidebar_open = status;
    },
    SET_SIDEBAR_OPEN_MOBILE(state, status) {
      state.sidebar_open_mobile = status;
    }
  },
  actions: {
    initApp({ commit }, accessToken) {
      localStorage.setItem("accessToken", accessToken);
      commit("SET_TOKEN", accessToken);
      http.defaults.headers.common = {
        Authorization: `Bearer ${accessToken}`
      };
      if (!localStorage.getItem("me")) {
        sessionApi.getMe(
          result => {
            commit("SET_USER", result.data);
          },
          error => {
            return error;
          }
        );
      } else {
        commit("SET_USER", JSON.parse(localStorage.getItem("me")));
      }
      if (!localStorage.getItem("sofom")) {
        sessionApi.getSofom(
          result => {
            commit("SET_SOFOM", result.data);
            commit("SET_IS_AUTHENTICATED");
            switch (result.data.env) {
              case "sandbox":
                document
                  .querySelector(":root")
                  .style.setProperty("--sidebar-primary", "#4cde9b");
                break;
              case "local":
                document
                  .querySelector(":root")
                  .style.setProperty("--sidebar-primary", "#ff5353");
                document
                  .querySelector(":root")
                  .style.setProperty("--sidebar-secondary", "#ff9b45");
                break;
            }
          },
          error => {
            return error;
          }
        );
      } else {
        commit("SET_SOFOM", JSON.parse(localStorage.getItem("sofom")));
        commit("SET_IS_AUTHENTICATED");
        switch (JSON.parse(localStorage.getItem("sofom")).env) {
          case "sandbox":
            document
              .querySelector(":root")
              .style.setProperty("--sidebar-primary", "#4cde9b");
            break;
          case "local":
            document
              .querySelector(":root")
              .style.setProperty("--sidebar-primary", "#ff5353");
            document
              .querySelector(":root")
              .style.setProperty("--sidebar-secondary", "#ff9b45");
            break;
        }
      }
      sessionApi.getAyudas(
        result => {
          commit("SET_AYUDAS", result.data);
        },
        error => {
          return error;
        }
      );
    },
    login({ dispatch }, data) {
      return sessionApi.login(
        data,
        res => {
          dispatch("initApp", res.data.access_token);
          return res;
        },
        error => {
          return error;
        }
      );
    },
    logOut(state, user) {
      return sessionApi.logOut(
        user,
        result => {
          return result;
        },
        error => {
          return error;
        }
      );
    },
    setHp({ commit }, value) {
      commit("SET_HARRY_POTTER", value);
      return true;
    },
    getAyudas({ commit }) {
      return sessionApi.getAyudas(
        result => {
          commit("SET_AYUDAS", result.data);
          return result.data;
        },
        error => {
          return error;
        }
      );
    },
    getSysAdmins({ commit }) {
      return sessionApi.getSysAdmins(
        result => {
          commit("SET_SYS_ADMINS", result.data);
          return result.data;
        },
        error => {
          return error;
        }
      );
    },
    setAyudaActual({ commit }, ayuda) {
      commit("SET_AYUDA_ACTUAL", ayuda);
      return true;
    },
    toggleAyudaActual({ commit }, ayuda_id) {
      return sessionApi.toggleAyuda(
        ayuda_id,
        result => {
          commit("SET_AYUDAS", result.data);
          commit(
            "SET_AYUDA_ACTUAL",
            result.data.find(ayuda => ayuda.id == ayuda_id)
          );
          return result;
        },
        error => {
          return error;
        }
      );
    },
    buscar({ commit }, data) {
      return sessionApi.buscar(
        data,
        result => {
          commit("SET_BUSQUEDA", result.data);
          commit("SET_BUSQUEDA_NOMBRE", data.val);
          return result.data;
        },
        error => error
      );
    },
    clearBusqueda({ commit }) {
      commit("SET_BUSQUEDA", null);
      commit("SET_BUSQUEDA_NOMBRE", null);
    },
    setSidebarOpen({ commit }, status) {
      commit("SET_SIDEBAR_OPEN", status);
    },
    setSidebarOpenMobile({ commit }, status) {
      commit("SET_SIDEBAR_OPEN_MOBILE", status);
    }
  },
  modules: {
    catalogos,
    empresa,
    garantias,
    disposicion,
    files,
    helpers,
    loader,
    lineascredito,
    pagos,
    personas,
    reportes,
    supervision,
    usuario,
    grupoCredito,
  }
});
