import config from "@/../config";
import http from "@/api/http";

export default {
  getParametrosPersonalizables(persona_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/parametros_personalizables/${persona_id}/persona`
      )
      .then(onSuccess)
      .catch(onError);
  },
  setParametros(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/parametros_personalizables/${data.persona_id}/persona`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  sendFeedback(data, onSuccess, onError) {
    return http
      .post(`${config.host}/${config.version}/feedback`, data)
      .then(onSuccess)
      .catch(onError);
  }
};
