import config from "@/../config";
import http from "@/api/http";

export default {
  getGarantiaById(garantia_id, onSuccess, onError) {
    return http
      .get(
        `${config.host}/${config.version}/garantias/${garantia_id}`
      )
      .then(onSuccess)
      .catch(onError);
  },
  reclamar(data, onSuccess, onError) {
    return http
      .patch(
        `${config.host}/${config.version}/garantias/${data.id}/reclamar`,
        data
      )
      .then(onSuccess)
      .catch(onError);
  },
  getPersonas(garantia_id, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/garantias/${garantia_id}/personas`)
      .then(onSuccess)
      .catch(onError);
  },
  buscar(params, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/garantias/search`, { params })
      .then(onSuccess)
      .catch(onError);
  },
  buscarActivoParaArrendamiento(search, onSuccess, onError) {
    return http
      .get(`${config.host}/${config.version}/garantias/searchActivo?search=${search}`)
      .then(onSuccess)
      .catch(onError);
  }
};
