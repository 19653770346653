<template>
  <div id="topNavbarContainer">
    <top-bar-full
      class="hidden md:flex"
      :config="config"
      :sofom="sofom"
      :me="me"
      :busqueda="getBusquedaNombre"
      @search="search"
    />
    <top-bar-mobile
      v-if="!getSidebarOpenMobile"
      class="flex md:hidden"
      @search="search"
    />
  </div>
</template>

<script>
import TopBarFull from "@/components/layout/TopBarFull";
import TopBarMobile from "@/components/layout/TopBarMobile";
import config from "@/../config";
import { mapGetters } from "vuex";

export default {
  name: "topNavbar",
  data() {
    return {
      config: null
    };
  },
  components: {
    TopBarFull,
    TopBarMobile
  },
  props: {
    sofom: Object,
    me: Object,
    host: null
  },
  computed: {
    ...mapGetters(["getMe", "getBusquedaNombre", "getSidebarOpenMobile"])
  },
  methods: {
    /* 
      Función que activa el buscador de capvital, en caso de ser el valor
      buscado "9 3/4", se activa el modo de harry potter
      @val (String): valor a buscar
    */
    search(val) {
      if (!val || val.length === 0) {
        this.$store.dispatch("clearBusqueda");
      } else {
        this.$store.dispatch("buscar", {
          val,
          filtros: []
        });
      }
      if (val === "9 3/4") {
        this.$store.dispatch("setHp", "hp");
        this.$buefy.snackbar.open({
          message:
            "<span class='font-bold'>¡Has activado el tipo de cambio del \
            mundo de harry potter! puedes obtener mas información en la \
            siguiente liga: <a target='_blank' class='underline' \
            href='https://harrypotter.fandom.com/es/wiki/Dinero_del_mundo_m%C3%A1gico'>\
            aquí</a></span></br>1 galeón = $126.71, 1 sickle = $6.57, 1 knut = 0.23 \
            </br><small>los montos son todos calculados en base a MXN por lo que pueden \
            existir algunos errores.</small>",
          type: "is-danger",
          position: "is-bottom",
          indefinite: true,
          actionText: "¡Regresar!",
          onAction: () => {
            this.search = null;
            this.$store.dispatch("setHp", "currency");
            this.$buefy.toast.open({
              duration: 4000,
              message: `¡De vuelta a las finanzas muggle!`,
              position: "is-top-right",
              type: "is-info"
            });
          }
        });
      }
    }
  },
  created() {
    this.config = config;
  }
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";

.img-container {
  padding: 0.6rem 2.4rem 0.6rem 0;
}
.search-input {
  border: none;
}
#topNavbarContainer {
  > div.full {
    z-index: 2;
    position: fixed;
    right: 0;
    width: 94%;
    border-top-left-radius: 52px;
    transition: all 150ms ease-out;
    @media screen and (max-width: 768px) {
    }
    top: -5px;
    padding-top: 5px;
    max-height: 6rem;
    .logo > img {
      max-height: 4rem;
    }
    @media screen and (max-width: 768px) {
      right: 0;
      margin-right: 0;
      padding: 0.75rem;
    }
  }
  > div.mobile {
    margin: 0;
    z-index: 2;
    position: fixed;
    left: 0;
    width: 100% !important;
    background-image: linear-gradient(
      to bottom,
      var(--sidebar-primary),
      var(--sidebar-secondary)
    ) !important;
    border-top-left-radius: 0;
  }
}
</style>
