<!-- 
Componente usado para tabs responsivos
-->

<template>
  <div>
    <b-tabs
      class="cursor-pointer hidden md:block mb-0"
      :class="{ 'is-pasivo': pasivo, 'is-arrendamiento': arrendamiento }"
      :type="type"
      :expanded="expanded"
      v-model="innerValue"
      :size="size"
      @input="$emit('change', innerValue)"
    >
      <b-tab-item
        v-for="(tab, index) in tabs"
        :value="index.toString()"
        :key="index"
        :label="tab.label"
        :disabled="tab.disabled"
        :visible="tab.visible"
      >
        <template slot="header">
          {{ tab.label }}
          <div v-if="tab.tags">
            <b-tag
              class="mx-1"
              v-for="tag in tab.tags"
              :type="tag.type"
              :key="tag.id"
              >{{ tag.number }}</b-tag
            >
          </div>
        </template>
      </b-tab-item>
    </b-tabs>
    <b-dropdown
      class="block md:hidden"
      aria-role="list"
      position="is-bottom-left"
      expanded
    >
      <b-button
        class="button is-light w-full"
        slot="trigger"
        slot-scope="{ active }"
        :icon-right="active ? 'caret-up' : 'caret-down'"
      >
        {{ innerValue != null ? tabs[innerValue].label : "Menú" }}
      </b-button>
      <b-dropdown-item
        v-for="(tab, index) in tabs"
        :key="tab.label"
        aria-role="listitem"
        :disabled="tab.disabled"
        :class="{ active: innerValue == index }"
        @click="() => (innerValue = index)"
      >
        {{ tab.label }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "tabs",
  data() {
    return {
      innerValue: undefined
    };
  },
  props: {
    tabs: Array,
    value: null,
    pasivo: {
      type: Boolean,
      default: false
    },
    arrendamiento: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "is-small"
    },
    type: {
      type: [String, null],
      default: "is-toggle-rounded"
    },
    expanded: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style>
.b-tabs .tab-content {
  padding: 0 !important;
}
</style>
