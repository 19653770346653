import ReportesApi from "@/api/reportes.api";

const state = {
  modelos: [],
  condusef_users:[],
  condusef_quejas:[],
  condusef_medios_recepcion:[],
  condusef_sentido_resolucion:[],
  condusef_niveles_atencion:[],
  condusef_productos:[],
  condusef_causas:[],
  condusef_estados:[],
  condusef_codigos_postales:[],
  condusef_municipios:[],
  condusef_colonias:[],
  condusef_redeco_consultas:null,
  condusef_redeco_reclamaciones:null,
  condusef_reune:null,
  condusef_reune_conf:null,
  condusef_redeco_conf:null
};
const getters = {
  getModelos: state => state.modelos,
  getCondusefUsers: state => state.condusef_users,
  getCondusefQuejas: state => state.condusef_quejas,
  getCondusefMediosRecepcion: state => state.condusef_medios_recepcion,
  getCondusefSentidoResolucion: state => state.condusef_sentido_resolucion,
  getCondusefNivelesAtencion: state => state.condusef_niveles_atencion,
  getCondusefProductos: state => state.condusef_productos,
  getCondusefCausas: state => state.condusef_causas,
  getCondusefEstados: state => state.condusef_estados,
  getCondusefCodigosPostales: state => state.condusef_codigos_postales,
  getCondusefMunicipios: state => state.condusef_municipios,
  getCondusefColonias: state => state.condusef_colonias,
  getCondusefConfigServiceReune: state => state.condusef_reune_conf,
  getCondusefConfigServiceRedeco: state => state.condusef_redeco_conf
};
const mutations = {
  SET_MODELOS(state, modelos) {
    state.modelos = modelos;
  },
  SET_CONDUSEF_USERS(state, data){
    state.condusef_users = data;
  },
  SET_CONDUSEF_QUEJAS(state, data){
    state.condusef_quejas = data;
  },
  SET_CONDUSEF_SENTIDO_RESOLUCION(state, data){
    state.condusef_sentido_resolucion = data;
  },
  SET_CONDUSEF_MEDIOS_RECEPCION(state, data){
    state.condusef_medios_recepcion = data;
  },
  SET_CONDUSEF_NIVELES_ATENCION(state, data){
    state.condusef_niveles_atencion = data;
  },
  SET_CONDUSEF_PRODUCTOS(state, data){
    state.condusef_productos = data;
  },
  SET_CONDUSEF_CAUSAS(state, data){
    state.condusef_causas = data;
  },
  SET_CONDUSEF_ESTADOS(state, data){
    state.condusef_estados = data;
  },
  SET_CONDUSEF_CODIGOS_POSTALES(state, data){
    state.condusef_codigos_postales = data;
  },
  SET_CONDUSEF_MUNICIPIOS(state, data){
    state.condusef_municipios = data;
  },
  SET_CONDUSEF_COLONIAS(state, data){
    state.condusef_colonias = data;
  },
  SET_CONDUSEF_REDECO(state, data){
    state.condusef_redeco = data;
  },
  SET_CONDUSEF_REUNE_CONSULTA(state, data){
    state.condusef_reune_consultas = data;
  },
  SET_CONDUSEF_REUNE_RECLAMACION(state, data){
    state.condusef_reune_reclamaciones = data;
  },
  SET_CONDUSEF_REUNE_CONF(state, data){
    state.condusef_reune_conf = data;
  },
  SET_CONDUSEF_REDECO_CONF(state, data){
    state.condusef_redeco_conf = data;
  }
};
const actions = {
  getModelos({ commit }) {
    return ReportesApi.getModelos(
      res => {
        commit("SET_MODELOS", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  getCondusefUsers({commit}){
    return ReportesApi.getCondusefUsers(
    res => {
      commit("SET_CONDUSEF_USERS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefQuejas({commit}, params){
    return ReportesApi.getCondusefQuejas(
    params,
    res => {
      commit("SET_CONDUSEF_QUEJAS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefMediosRecepcion({commit}, params){
    return ReportesApi.getCondusefMediosRecepcion(
      params,
    res => {
      commit("SET_CONDUSEF_MEDIOS_RECEPCION",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefSentidoResolucion({commit}, params){
    return ReportesApi.getCondusefSentidoResolucion(
      params,
    res => {
      commit("SET_CONDUSEF_SENTIDO_RESOLUCION",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefNivelesAtencion({commit}, params){
    return ReportesApi.getCondusefNivelesAtencion(
    params,
    res => {
      commit("SET_CONDUSEF_NIVELES_ATENCION",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefProductos({commit},params){
    return ReportesApi.getCondusefProductos(
    params,
    res => {
      commit("SET_CONDUSEF_PRODUCTOS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefCausas({commit}, params){
    return ReportesApi.getCondusefCausas(
    params,
    res => {
      commit("SET_CONDUSEF_CAUSAS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefEstados({commit}, params){
    return ReportesApi.getCondusefEstados(
    params,
    res => {
      commit("SET_CONDUSEF_ESTADOS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefCodigosPostales({commit}, params){
    return ReportesApi.getCondusefCodigosPostales(
    params,
    res => {
      commit("SET_CONDUSEF_CODIGOS_POSTALES",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefMunicipios({commit}, params){
    return ReportesApi.getCondusefMunicipios(
    params,
    res => {
      commit("SET_CONDUSEF_MUNICIPIOS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefColonias({commit}, params){
    return ReportesApi.getCondusefColonias(
    params,
    res => {
      commit("SET_CONDUSEF_COLONIAS",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  postCondusefRedeco({commit}, data){
    return ReportesApi.postCondusefRedeco(
    data,
    res => {
      commit("SET_CONDUSEF_REDECO",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  postCondusefReuneConsulta({commit}, data){
    return ReportesApi.postCondusefReuneConsulta(
    data,
    res => {
      commit("SET_CONDUSEF_REUNE_CONSULTA",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  postCondusefReuneReclamacion({commit}, data){
    return ReportesApi.postCondusefReuneReclamacion(
    data,
    res => {
      commit("SET_CONDUSEF_REUNE_RECLAMACION",res.data)
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefConfigServiceReune({commit}){
    return ReportesApi.getCondusefConfigService(
      {tipo_reporte:'reune'},
    res => {
      commit("SET_CONDUSEF_REUNE_CONF",res.data);
      return res.data;
    },
    error=>{
      return error;
    });
  },
  getCondusefConfigServiceRedeco({commit}){
    return ReportesApi.getCondusefConfigService(
    {tipo_reporte:'redeco'},
    res => {
      commit("SET_CONDUSEF_REDECO_CONF",res.data);
      return res.data;
    },
    error=>{
      return error;
    });
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
