<template>
  <ValidationProvider
    :rules="rules == 'none' ? '' : rules"
    v-slot="{ errors, valid }"
  >
    <b-field
      :type="{
        'is-danger': errors[0],
        'is-success': valid && rules != 'none'
      }"
      :label="label"
      :message="errors"
      :custom-class="labelClass"
    >
      <b-input
        :placeholder="placeholder"
        :type="type"
        :icon="icon"
        :class="[inputclass, { 'w-9/10': addon == true }]"
        :disabled="disabled"
        v-model="innerValue"
        @input="tellParent"
        :expanded="expanded"
      >
      </b-input>
      <p class="control w-1/10" v-if="addon">
        <span class="button is-static has-background-grey-lighter">
          <fa-icon :icon="addonIcon" size="lg" />
        </span>
      </p>
      <slot></slot>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "validInput",
  props: {
    rules: String,
    label: String,
    placeholder: String,
    type: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    inputclass: String,
    value: null,
    expanded: {
      type: Boolean,
      default: false
    },
    addon: {
      type: Boolean,
      default: false
    },
    addonIcon: String,
    labelClass: String
  },
  components: {
    ValidationProvider
  },
  data: () => ({
    innerValue: ""
  }),
  methods: {
    tellParent(newVal) {
      this.$emit("directInput", newVal);
    }
  },
  watch: {
    innerValue(newVal) {
      if (this.type == "number") {
        newVal = Number(newVal);
      }
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
