import UsuarioApi from "@/api/usuario.api";

const state = {
  parametros_personalizables: null,
  feedback: null
};
const getters = {
  getParametros: state => state.parametros_personalizables
};
const mutations = {
  SET_PARAMS_PERSONALIZABLES(state, params) {
    state.parametros_personalizables = params;
  },
  SET_FEEDBACK(state, feedback) {
    state.feedback = feedback;
  }
};
const actions = {
  getParametros({ commit }, persona_id) {
    return UsuarioApi.getParametrosPersonalizables(
      persona_id,
      res => {
        commit("SET_PARAMS_PERSONALIZABLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  setParametros({ commit }, data) {
    return UsuarioApi.setParametros(
      data,
      res => {
        commit("SET_PARAMS_PERSONALIZABLES", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  },
  sendFeedback({ commit }, data) {
    return UsuarioApi.sendFeedback(
      data,
      res => {
        commit("SET_FEEDBACK", res.data);
        return res.data;
      },
      error => {
        return error;
      }
    );
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
