<template>
  <div id="app">
    <Loading v-if="loading" description="Cargando"></Loading>
    <router-view />
  </div>
</template>

<script>
import Loading from "@/components/layout/Loading";
import NProgress from "nprogress";
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
import hotkeys from "hotkeys-js";

export default {
  name: "app",
  components: {
    Loading
  },
  data() {
    return {
      loading: null
    };
  },
  methods: {
    jiraHelpdesk(callback) {
      let jhdScript = document.createElement('script');
      jhdScript.type = 'text/javascript';
      jhdScript.setAttribute('data-jsd-embedded', null);
      jhdScript.setAttribute('data-key', 'e2d65ff2-140c-4572-a371-366bbc084ad2');
      jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
      jhdScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
      if(jhdScript.readyState) { // old IE support
      jhdScript.onreadystatechange = function() {
        if ( jhdScript.readyState === 'loaded' || jhdScript.readyState === 'complete' ) {
        jhdScript.onreadystatechange = null;
        callback();
        }
      };
      } else { //modern browsers
      jhdScript.onload = function() {
        callback();
      };
      }
      document.getElementsByTagName('head')[0].appendChild(jhdScript);
    }
  },
  mounted() {
    // TOGGLE DE DARKMODE Y WHITE MODE
    let bodyElement = document.body;
    bodyElement.classList.add("app-background");
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem("theme");
    if (theme === "dark") {
      htmlElement.setAttribute("theme", "dark");
      this.darkMode = true;
    } else {
      htmlElement.setAttribute("theme", "light");
      this.darkMode = false;
    }
    if (this.$route.name == "login") {
      this.loading = false;
    }
    //HOTKEYS
    hotkeys("ctrl+shift+f", () => {
      if (document.getElementById("buscador")) {
        document.getElementById("buscador").focus();
        document.getElementById("buscador").select();
      } else {
        document.getElementById("topBuscador").focus();
        document.getElementById("topBuscador").select();
      }
    });
    hotkeys("ctrl+shift+.", () => {
      document.getElementById("myProfile").click();
    });
  },
  created() {
    sessionStorage.setItem("modalOpened", false);
    /*
     Este watch en la store se encarga del manejo del modal de cargando asi como de la barra superior que se muestra
     dependiendo de las peticiones a la API
    */
    this.$store.watch(
      state => state.loader.loading,
      (newVal, oldVal) => {
        if (newVal <= 0) {
          this.loading = false;
          //Si incluye el query param tk, se remueve una vez que se hayan hecho las comprobaciones necesarias en el middleware del router
          if (window.location.href.includes("?tk=") || window.location.href.includes("&tk=")) {
            window.history.pushState(
              "Conexion entre versiones",
              "Redireccion",
              window.location.href.includes("?tk=") ? window.location.href.split("?tk=")[0] : window.location.href.split("&tk=")[0]
            );
          }
          return NProgress.done();
        }
        if (newVal < 0) this.$store.commit("loader/SET_CERO");
        if (oldVal === 0) {
          this.loading = true;
          return NProgress.start();
        }
        NProgress.set(1.8 / Math.max(oldVal, newVal));
      }
    );

    this.jiraHelpdesk(function() {
      let DOMContentLoaded_event = document.createEvent('Event');
      DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);
    });

  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
#app {
  font-family: Ubuntu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html {
  background-color: var(--white) !important;
}
#nprogress .bar {
  background-color: var(--sidebar-primary) !important;
  height: 5px !important;
  .peg {
    box-shadow: 0 0 10px var(--sidebar-primary),
      0 0 5px var(--sidebar-secondary);
  }
}
</style>
